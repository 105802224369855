import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";

import { DefaultPaper, LoadingButton } from "@aclymatepackages/atoms";

import PurchaseFlowPaymentInfoBox from "./PurchaseFlowPaymentInfoBox";
import SubscriptionAccountCreation from "./SubscriptionAccountCreation";

import Link from "../atoms/mui/Link";
import PurchaseDialogLayout from "../layouts/PurchaseDialogLayout";

import { fetchOurApi } from "../../helpers/utils/apiCalls";
import { analyticsTrack } from "../../helpers/analytics";
import {
  useCustomerPaymentMethod,
  useCreateStripeSubscription,
} from "../../helpers/hooks/stripe";
import { StripeCustomerContext } from "../../helpers/contexts/stripeCustomer";
import { useAuth, useCachedFirebaseCrud } from "../../helpers/firebase";
import { getAccountCollectionAndId } from "../../helpers/otherHelpers";

const SubscriptionSignupBlock = ({
  selectedOffsetBucket,
  individualData,
  stripePriceId,
  percentage,
  price,
}) => {
  const { email, name } = individualData;

  const { updateAccountData } = useCachedFirebaseCrud();
  const { stripeSubmit } = useCreateStripeSubscription();

  const [saveLoading, setSaveLoading] = useState(false);

  const { name: title, id: portfolioId } = selectedOffsetBucket || {};

  return (
    <Grid container spacing={1} direction="column" alignItems="center">
      <Grid item>
        <LoadingButton
          isLoading={saveLoading}
          label="Subscribe"
          color="secondary"
          onClick={async () => {
            setSaveLoading(true);

            await stripeSubmit({
              subscriptionType: "myaclymateCNaught",
              stripePriceId,
              interval: "month",
              product: title,
              value: price,
              percentage,
              metadata: { percentage },
            });

            const { subaccountId } = await fetchOurApi({
              path: "/integrations/cNaught/create-subaccount",
              method: "POST",
              data: {
                name,
                email,
                portfolioId,
              },
              callback: (res) => res,
            });
            updateAccountData({ cNaughtSubaccountId: subaccountId });

            return analyticsTrack(
              `myAclymate Offset Subscription ${percentage}%`,
              {
                email,
                title,
              }
            );
          }}
        />
      </Grid>
      <Grid item>
        <Typography
          color="textSecondary"
          align="center"
          variant="caption"
          component="div"
        >
          {`By clicking this button you agree to immediately create a subscription for our ${title} plan. You will be automatically billed on the interval that you've selected until you cancel. Your payment method will immediately be billed the amount shown above.`}
        </Typography>
      </Grid>
    </Grid>
  );
};

const SubscriptionPurchaseBox = ({
  selectedOffsetBucket,
  individualData,
  paymentMethodId,
  handleSubscribeOnClick,
  percentage,
  price,
}) => {
  const {
    name: title,
    summary: descriptor,
    tagline,
    stripePriceId,
    id: portfolioId,
  } = selectedOffsetBucket || {};

  return (
    <PurchaseDialogLayout title={title} subtitle={tagline}>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="body1">{descriptor}</Typography>
          </Grid>
          <Grid item>
            {paymentMethodId ? (
              <SubscriptionSignupBlock
                selectedOffsetBucket={selectedOffsetBucket}
                individualData={individualData}
                stripePriceId={stripePriceId}
                percentage={percentage}
                price={price}
                portfolioId={portfolioId}
              />
            ) : (
              <Grid item container justifyContent="center">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSubscribeOnClick}
                >
                  Subscribe
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </PurchaseDialogLayout>
  );
};

const MyAclymateSurveyOffsetSubscriptionPurchase = ({
  individualId,
  portfolio,
  price,
  setSubscriptionData,
}) => {
  const [user, userLoading] = useAuth();

  const [individualData, setIndividualData] = useState({});
  const [paymentFlowStep, setPaymentFlowStep] = useState("");

  const { uids, stripeCustomerId } = individualData;
  const { percentage, price: displayPrice } = price || {};

  const { paymentMethod, isPaymentMethodLoading } =
    useCustomerPaymentMethod(stripeCustomerId);

  const {
    subscriptions,
    isSubscriptionsLoading,
    refetchCustomerSubscriptions,
  } = useContext(StripeCustomerContext);
  const { paymentMethodId } = paymentMethod || {};

  const findSubscriptionByType = (subscriptionType) =>
    subscriptions.find(({ type }) => type === subscriptionType);

  const {
    type: currentOffsetSubscriptionType,
    status: currentOffsetSubscriptionStatus,
    name: currentOffsetSubscriptionName,
  } = findSubscriptionByType("myaclymateCNaught") || {};

  const { name: title } = portfolio || {};

  useEffect(() => {
    if (individualId && !Object.keys(individualData).length) {
      fetchOurApi({
        path: `/individuals/${individualId}`,
        method: "GET",
        callback: (res) => {
          setIndividualData(res);

          const { stripeCustomerId } = res || {};

          return refetchCustomerSubscriptions(stripeCustomerId);
        },
      });
    }
  });

  useEffect(() => {
    const setSessionStorageAccountId = async () => {
      const { id: individualId } = getAccountCollectionAndId();

      if (!individualId && user && !userLoading) {
        const { claims } = await user.getIdTokenResult({ forceRefresh: true });
        const { individual } = claims;

        window.sessionStorage.setItem("accountId", `individuals-${individual}`);
      }
    };

    setSessionStorageAccountId();
  }, [user, userLoading]);

  const changePaymentFlowStep = () => {
    if (!uids || !user) {
      return setPaymentFlowStep("create-account");
    }

    if (uids && !user) {
      return setPaymentFlowStep("login");
    }

    if (!paymentMethodId) {
      return setPaymentFlowStep("payment-information");
    }

    return setPaymentFlowStep("");
  };

  const generateContent = () => {
    if (
      !Object.keys(individualData).length ||
      isPaymentMethodLoading ||
      isSubscriptionsLoading ||
      userLoading
    ) {
      return (
        <DefaultPaper style={{ width: "100%" }}>
          <Grid item container justifyContent="center">
            <CircularProgress />
          </Grid>
        </DefaultPaper>
      );
    }

    if (
      currentOffsetSubscriptionType &&
      currentOffsetSubscriptionStatus === "active"
    ) {
      return (
        <PurchaseDialogLayout
          title="Thank you for subscribing!"
          subtitle={`You are subscribed to the ${currentOffsetSubscriptionName} plan.`}
        >
          <Box p={2}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="body1">
                  You can manage your subscription directly from your updated
                  report
                </Typography>
              </Grid>
              <Grid item container justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setSubscriptionData(null)}
                >
                  Back to the report
                </Button>
              </Grid>
            </Grid>
          </Box>
        </PurchaseDialogLayout>
      );
    }

    if (uids && !user) {
      return (
        <PurchaseDialogLayout title="You need to login to make this purchase">
          <Link href="login">
            <Box p={2} display="flex" justifyContent="center">
              <Button variant="contained" color="secondary">
                Login
              </Button>
            </Box>
          </Link>
        </PurchaseDialogLayout>
      );
    }

    if (paymentFlowStep === "create-account") {
      return (
        <SubscriptionAccountCreation
          individualData={individualData}
          setIndividualData={setIndividualData}
          setPaymentFlowStep={setPaymentFlowStep}
        />
      );
    }

    if (paymentFlowStep === "payment-information") {
      return (
        <PurchaseFlowPaymentInfoBox
          individual={individualData}
          dialogSubtitle="Please enter your credit card information to complete your subscription."
          selectedOffsetBucket={portfolio}
          subscriptionTitle={title}
          percentage={percentage}
        />
      );
    }

    return (
      <SubscriptionPurchaseBox
        selectedOffsetBucket={portfolio}
        individualData={individualData}
        paymentMethodId={paymentMethodId}
        handleSubscribeOnClick={changePaymentFlowStep}
        percentage={percentage}
        price={displayPrice}
      />
    );
  };

  return <Container maxWidth="md">{generateContent()}</Container>;
};
export default MyAclymateSurveyOffsetSubscriptionPurchase;
