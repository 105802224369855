import { useEffect, useState, useContext } from "react";
import { initializeApp } from "firebase/app";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { getAuth } from "firebase/auth";
import {
  Timestamp,
  arrayUnion,
  arrayRemove,
  deleteField,
  initializeFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  writeBatch,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { getPerformance } from "firebase/performance";

import { useAuthState } from "react-firebase-hooks/auth";
import dayjs from "dayjs";
import useSWR, { useSWRConfig } from "swr";
import { isEqual } from "lodash";

import { DEFAULT_START_DATE } from "@aclymatepackages/constants";
import { isCountryUsa } from "@aclymatepackages/other-helpers";

import { isObjectEmpty, getAccountCollectionAndId } from "./otherHelpers";
import { AdminDataContext } from "./contexts/adminData";
import { PlatformLayoutContext } from "./contexts/platformLayout";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = initializeFirestore(app, { ignoreUndefinedProperties: true });
export const auth = getAuth(app);

getPerformance(app);

const adminDataCollections = [
  "offices",
  "employees",
  "vehicles",
  "offsets",
  "events",
  "vendors",
  "all-aggregated-transactions",
  "matched-vendors-aggregated-transactions",
  "employees-aggregated-transactions",
  "events-aggregated-transactions",
];

const nonCompanyCollectionNames = {
  events: "events",
  employees: "individuals",
  "vendor-companies": "v2-companies",
};

export const useAuth = () => useAuthState(getAuth());

export const signOut = async () => await getAuth().signOut();

const buildDocRef = (collectionName, docId) => {
  const { collection: accountsCollection, id: accountId } =
    getAccountCollectionAndId();

  if (!collectionName) {
    return doc(db, `${accountsCollection}/${accountId}`);
  }

  if (Object.keys(nonCompanyCollectionNames).includes(collectionName)) {
    return doc(db, `${nonCompanyCollectionNames[collectionName]}/${docId}`);
  }

  return doc(
    db,
    `${accountsCollection}/${accountId}/${collectionName}/${docId}`
  );
};

const buildCollectionRef = (collectionName) => {
  if (Object.keys(nonCompanyCollectionNames).includes(collectionName)) {
    const collectionPath = nonCompanyCollectionNames[collectionName];
    return collection(db, collectionPath);
  }

  const { collection: accountsCollection, id: accountId } =
    getAccountCollectionAndId();

  return collection(db, `${accountsCollection}/${accountId}/${collectionName}`);
};

const buildCollectionQuery = (collectionName, queries = []) => {
  const { id: accountId } = getAccountCollectionAndId();

  const collectionQueries = {
    employees: [["currentLinkedIds", "array-contains", accountId]],
    events: [["companyIds", "array-contains", accountId]],
    "vendor-companies": [["buyerIds", "array-contains", accountId]],
  };

  const additionalQueries = collectionQueries[collectionName] || [];

  const queriesArray = [...additionalQueries, ...queries].map((queryArray) =>
    where(...queryArray)
  );

  const collectionRef = buildCollectionRef(collectionName);

  return query(collectionRef, ...queriesArray);
};

const fetchCollectionRefQuery = async (collectionName, queries = []) => {
  const firebaseQuery = buildCollectionQuery(collectionName, queries);

  return await getDocs(firebaseQuery)
    .then((rawData) => {
      return rawData.docs.map((doc) => ({
        ...unpackFirebaseObj(doc.data()),
        id: doc.id,
      }));
    })
    .catch((e) => {
      console.log(`error fetching ${collectionName} documents: ` + e);
      throw new Error(e);
    });
};

const dateToFirebaseTimeStamp = (date = null) =>
  new Timestamp(dayjs(date).unix(), 0);

export const firebaseArrayUnionObj = (data) => ({ data, arrayUnion: true });

const firebaseArrayUnion = (value) => {
  if (Array.isArray(value)) {
    return arrayUnion(...value);
  }
  return arrayUnion(value);
};

export const firebaseArrayRemoveObj = (data) => ({ data, arrayRemove: true });

export const firebaseArrayRemove = (value) => arrayRemove(value);

export const firebaseFieldDeleteObj = () => ({ deletable: true });

export const prepareFirebaseObj = (obj) =>
  Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => !(value === undefined))
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return [
            key,
            value.map((row) =>
              typeof row === "string" || typeof row === "number"
                ? row
                : prepareFirebaseObj(row)
            ),
          ];
        }

        if (typeof value === "string" && !isNaN(value)) {
          return [key, Number(value)];
        }

        if (
          typeof key === "string" &&
          typeof value === "string" &&
          RegExp(/^\d{1,2}(\/|-)\d{1,2}(\/|-)(?:\d{4}|\d{2})$/, "g").test(
            value
          ) &&
          !isObjectEmpty(value)
        ) {
          return [key, dateToFirebaseTimeStamp(new Date(value))];
        }

        if (value && typeof value === "object") {
          if (dayjs.isDayjs(value)) {
            const convertedToDateObj = dayjs(value).toDate();
            return [key, dateToFirebaseTimeStamp(convertedToDateObj)];
          }

          if (Object.prototype.toString.call(value) === "[object Date]") {
            return [key, dateToFirebaseTimeStamp(dayjs(value).toDate())];
          }

          const { seconds, data, arrayUnion, arrayRemove, deletable } = value;

          if (seconds) {
            return [key, value];
          }
          if (arrayUnion && data) {
            return [key, firebaseArrayUnion(data)];
          }

          if (arrayRemove && data) {
            return [key, firebaseArrayRemove(data)];
          }

          if (deletable) {
            return [key, deleteField()];
          }

          return [key, prepareFirebaseObj(value)];
        }

        return [key, value];
      })
  );

export const unpackFirebaseObj = (obj) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [
          key,
          value.map((row) =>
            typeof row === "string" || typeof row === "number"
              ? row
              : unpackFirebaseObj(row)
          ),
        ];
      }
      if (value && typeof value === "object") {
        const { seconds, _seconds } = value || {};
        if (seconds || _seconds) {
          return [key, new Date((seconds || _seconds) * 1000)];
        }
        return [key, unpackFirebaseObj(value)];
      }
      return [key, value];
    })
  );

export const useDestructuredSWRdata = (queryCache, fetcher) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { data: rawData, error: rawError } = useSWR(queryCache, fetcher);

  useEffect(() => {
    if (!rawError && (!isObjectEmpty(rawData) || Array.isArray(rawData))) {
      setData(rawData);
      setLoading(false);
    }
    if (rawError) {
      setError(rawError);
      setLoading(false);
    }
  }, [rawData, rawError]);

  return [data, loading, error];
};

const fetchAccountData = async () =>
  await getDoc(buildDocRef())
    .then((doc) => unpackFirebaseObj({ id: doc.id, ...doc.data() }))
    .catch((error) => {
      console.log("account data error", error);
      throw new Error(error);
    });

export const useAccountData = () => {
  const { viewMode } = useContext(PlatformLayoutContext) || {};
  const { adminData = {}, isAdminDataLoading } =
    useContext(AdminDataContext) || {};

  const accountDataFromSWR = useDestructuredSWRdata(
    "accountData",
    () => fetchAccountData(),
    {}
  );

  const insertDefaultCompanyParams = (companyData) => {
    const { startDate, measurementSystem, geography } = companyData || {};
    const defaultMeasurementSystem = isCountryUsa(geography?.address?.country)
      ? "imperial"
      : "metric";

    return {
      ...companyData,
      startDate: startDate || DEFAULT_START_DATE,
      measurementSystem: measurementSystem || defaultMeasurementSystem,
    };
  };

  if (viewMode === "admin") {
    const { companyData } = adminData;

    return [insertDefaultCompanyParams(companyData), isAdminDataLoading];
  }

  const [accountData, ...otherProps] = accountDataFromSWR;

  return [insertDefaultCompanyParams(accountData), ...otherProps];
};

const mutateNestedData = (existingObj, updateObj) =>
  Object.fromEntries(
    Object.entries(updateObj).map(([key, value]) => {
      if (!key.includes(".")) {
        return [key, value];
      }
      const keys = key.split(".");
      const existingData = existingObj[keys[0]];
      return [keys[0], { ...existingData, [keys[1]]: value }];
    })
  );

export const useCollectionDataListener = (collection, queries = []) => {
  const { viewMode } = useContext(PlatformLayoutContext) || {};
  const { adminData = {}, isAdminDataLoading } =
    useContext(AdminDataContext) || {};

  const [dbData, setDbData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    const firebaseQuery = buildCollectionQuery(collection, queries);

    const unsubscribe = onSnapshot(firebaseQuery, (snap) => {
      const data = snap.docs.map((doc) => ({
        ...unpackFirebaseObj(doc.data()),
        id: doc.id,
      }));

      if (dataLoading || !isEqual(data, dbData)) {
        setDbData(data);
        setDataLoading(false);
      }
    });

    return () => unsubscribe();
  }, [collection, queries, dataLoading, dbData]);

  if (viewMode === "admin" && adminDataCollections.includes(collection)) {
    return [adminData[collection], isAdminDataLoading];
  }

  return [dbData, dataLoading];
};

export const useCachedDisplayData = (collection, queries = []) => {
  const { viewMode } = useContext(PlatformLayoutContext) || {};
  const { adminData = {}, isAdminDataLoading } =
    useContext(AdminDataContext) || {};

  const [dbCollection, dbCollectionLoading] = useDestructuredSWRdata(
    collection,
    () => fetchCollectionRefQuery(collection, queries)
  );

  if (viewMode === "admin" && adminDataCollections.includes(collection)) {
    return [adminData[collection], isAdminDataLoading];
  }

  return [dbCollection, dbCollectionLoading];
};

const prepareUpdateCacheObj = (updateObj, existingDoc = {}) =>
  Object.fromEntries(
    Object.entries(updateObj).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [
          key,
          value.map((row) =>
            typeof row === "string" || typeof row === "number"
              ? row
              : prepareUpdateCacheObj(row)
          ),
        ];
      }

      if (value && typeof value === "object") {
        const { data, arrayUnion, arrayRemove } = value;
        if (!arrayUnion && !arrayRemove) {
          return [key, value];
        }

        const existingField = existingDoc[key];
        if (arrayUnion && Array.isArray(existingField)) {
          return [key, [...existingField, data]];
        }

        if (arrayRemove && Array.isArray(existingField)) {
          const newArray = existingField.filter(({ id }) => id === data.id);
          return [key, newArray];
        }
      }

      return [key, value];
    })
  );

export const addCollectionDoc = async (collection, data) => {
  const collectionRef = buildCollectionRef(collection);

  const docId = await addDoc(collectionRef, prepareFirebaseObj(data)).then(
    (docRef) => docRef.id
  );

  return docId;
};

export const updateDocRef = async (collection, docId, updateObj) => {
  const docRef = buildDocRef(collection, docId);
  return await updateDoc(docRef, prepareFirebaseObj(updateObj));
};

export const setDocRef = async (collection, docId, docProps) => {
  const docRef = buildDocRef(collection, docId);
  return await setDoc(docRef, prepareFirebaseObj(docProps));
};

export const useCachedFirebaseCrud = () => {
  const { mutate } = useSWRConfig();
  const { viewMode } = useContext(PlatformLayoutContext) || {};
  const { adminData, editAdminData } = useContext(AdminDataContext) || {};

  const viewModeDataObj = {
    admin: {
      viewModeData: adminData,
      editViewModeData: editAdminData,
    },
  };
  const { viewModeData, editViewModeData } = viewModeDataObj[viewMode] || {};
  const isViewModeNotCompany = viewMode !== "company";

  const modifyViewModeData = (dbCollection, modificationFunction) => {
    const currentViewModeData = viewModeData[dbCollection];
    const newViewModeData = modificationFunction(currentViewModeData);

    return editViewModeData(collection, newViewModeData);
  };

  const updateAccountData = async (updateObj, refetch = true) => {
    mutate(
      "accountData",
      (companyData) => {
        return prepareUpdateCacheObj(
          {
            ...companyData,
            ...mutateNestedData(companyData, updateObj),
          },
          companyData
        );
      },
      false
    );

    if (!refetch) {
      return;
    }

    await updateDoc(buildDocRef(), prepareFirebaseObj(updateObj));
    return mutate("accountData");
  };

  const batchNewCollectionDocs = async (dbCollection, newObjects) => {
    const batchAddLocalDocs = (currentCollection = []) => {
      const newObjectsTempIds = newObjects.map((data) => ({
        ...data,
        id: dayjs().valueOf() * Math.random(),
      }));
      return [...currentCollection, ...newObjectsTempIds];
    };

    if (isViewModeNotCompany) {
      return modifyViewModeData(dbCollection, batchAddLocalDocs);
    }

    const { collection: accountsCollection, id } = getAccountCollectionAndId();

    mutate(dbCollection, batchAddLocalDocs, false);

    const batch = writeBatch(db);

    const newDocIds = newObjects.map((obj) => {
      const newDocRef = doc(
        collection(db, `${accountsCollection}/${id}/${dbCollection}`)
      );
      batch.set(newDocRef, obj);
      return { ...obj, id: newDocRef.id };
    });

    await batch.commit().then(() => mutate(dbCollection));
    return newDocIds;
  };

  const setCollectionDoc = async (collection, docId, docProps) => {
    const setLocalData = (currentData) => {
      if (!currentData) {
        return;
      }

      return currentData.map((doc) => {
        if (docId === doc.id) {
          return prepareUpdateCacheObj(docProps);
        }
        return doc;
      });
    };

    if (isViewModeNotCompany) {
      return modifyViewModeData(collection, setLocalData);
    }

    mutate(collection, setLocalData, false);

    const docRef = buildDocRef(collection, docId);

    return await setDoc(docRef, prepareFirebaseObj(docProps))
      .then(() => mutate(collection))
      .catch((e) => {
        console.log("firebase update error: " + JSON.stringify(e));
        mutate(collection);
        throw new Error(e);
      });
  };

  const updateCollectionDoc = async (collection, docId, updateObj) => {
    const updateLocalData = (currentData) => {
      if (!currentData) {
        return;
      }

      return currentData.map((doc) => {
        if (docId === doc.id) {
          const preparedObj = prepareUpdateCacheObj(updateObj, doc);

          return { ...doc, ...preparedObj };
        }
        return doc;
      });
    };

    if (isViewModeNotCompany) {
      return modifyViewModeData(collection, updateLocalData);
    }

    mutate(collection, updateLocalData, false);

    return await updateDocRef(collection, docId, updateObj)
      .then(() => mutate(collection))
      .catch((e) => {
        console.log("firebase update error: " + JSON.stringify(e));
        mutate(collection);
        throw new Error(e);
      });
  };

  const newCollectionDoc = async (collection, data) => {
    const addLocalData = (currentCollection = []) => {
      const newObj = { ...data, id: new Date().toISOString() };
      return [...currentCollection, newObj];
    };

    if (isViewModeNotCompany) {
      return modifyViewModeData(collection, addLocalData);
    }

    mutate(collection, addLocalData, false);

    const docId = await addCollectionDoc(collection, data);

    mutate(collection);
    return docId;
  };

  const deleteCachedCollectionDoc = async (collection, docId) => {
    const deleteLocalData = (currentCollection = []) =>
      currentCollection.filter(({ id }) => id !== docId);

    if (isViewModeNotCompany) {
      return modifyViewModeData(collection, deleteLocalData);
    }

    mutate(
      collection,
      (currentCollection) =>
        currentCollection && currentCollection.filter(({ id }) => id !== docId),
      false
    );

    return await deleteDoc(buildDocRef(collection, docId)).then(() =>
      mutate(collection)
    );
  };

  return {
    setCollectionDoc,
    updateCollectionDoc,
    updateAccountData,
    newCollectionDoc,
    batchNewCollectionDocs,
    deleteCachedCollectionDoc,
  };
};

export const uploadFileToStorage = async ({
  path,
  fileObject,
  fileMetadata,
  hasUserConfirmedUpload,
}) => {
  const storageRef = ref(storage, path);

  const checkForDuplicateFile = async () => {
    try {
      await getDownloadURL(storageRef);

      return true;
    } catch (e) {
      return false;
    }
  };

  const isDuplicateFile = await checkForDuplicateFile();

  if (isDuplicateFile && !hasUserConfirmedUpload) {
    return { isDuplicateFileDetected: true };
  }

  const uploadTask = uploadBytesResumable(storageRef, fileObject, fileMetadata);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        console.log("progress", progress);
      },
      (error) => {
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((url) => {
            resolve(url);
          })
          .catch((error) => {
            reject(error);
            throw new Error(error);
          });
      }
    );
  });
};
