import React, { useState, useEffect, useCallback, useRef } from "react";
import dayjs from "dayjs";

import {
  Avatar,
  Box,
  Grid,
  Button,
  Tooltip,
  ButtonBase,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

import {
  editRowData,
  editObjectData,
} from "@aclymatepackages/array-immutability-helpers";
import { Popper } from "@aclymatepackages/atoms";

import DatePopper from "./DatePopper";

import SendEmployeeSurveyButton from "../../../../atoms/buttons/SendEmployeeSurveyButton";
import CommuteSchedulesInterface from "../../../../inputs/employees/CommuteSchedulesInterface";
import WorkspaceGraphicCard from "../../../../inputs/employees/WorkspaceGraphicCard";

import {
  fetchOurApi,
  fetchDirectionsMileage,
} from "../../../../../helpers/utils/apiCalls";
import { generateTempId } from "../../../../../helpers/otherHelpers";
import {
  useCachedDisplayData,
  useAccountData,
  useCachedFirebaseCrud,
} from "../../../../../helpers/firebase";
import { useOfficesWithCurrentChanges } from "../../../../../helpers/components/offices";
import useAccountingData from "../../../../../helpers/hooks/accountingData";
import { fetchAddressEGrid } from "../../../../../helpers/utils/apiCalls";

const CommuteTimelineDateAvatar = ({
  originalSchedulesWithTempIds,
  setCommuteSchedulesWithTempIds,
  date,
  isActive,
  employee,
  position = { right: "-20px" },
  anchorEl,
  setAnchorEl,
  selectedScheduleProps,
  onClick,
  clickDisabled,
  activePopper,
}) => {
  const theme = useTheme();

  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const month = dayjs(date).month() + 1;
  const year = dayjs(date).format("YY");

  const { id } = employee;
  const { adjSchedule } = selectedScheduleProps || {};

  const onNewDateSelect = () => {
    const newCommuteSchedules = originalSchedulesWithTempIds.map(
      ({ tempId, ...otherProps }) => {
        if (tempId === selectedScheduleProps.tempId) {
          return {
            ...otherProps,
            endDate: dayjs(date).toDate(),
          };
        }
        if (tempId === adjSchedule?.tempId) {
          return {
            ...otherProps,
            startDate: dayjs(date).add(1, "day").toDate(),
          };
        }
        return otherProps;
      }
    );

    setCommuteSchedulesWithTempIds(newCommuteSchedules);
    return updateCollectionDoc("employees", id, {
      commuteSchedules: newCommuteSchedules,
    });
  };

  return (
    <>
      <ButtonBase
        onClick={(e) => onClick(e)}
        style={{
          position: "absolute",
          zIndex: 9,
          top: "-8px",
          ...position,
        }}
        disabled={clickDisabled}
      >
        <Avatar
          style={{
            backgroundColor: isActive
              ? theme.palette.employees.main
              : theme.palette.backgroundGray.dark,
            border: "2px white solid",
          }}
        >
          <Typography
            variant="caption"
            align="center"
            style={{ color: "white" }}
          >
            {`${month}/${year}`}
          </Typography>
        </Avatar>
      </ButtonBase>
      <DatePopper
        title="When did this commute schedule start?"
        buttonText="Change Start Date"
        open={activePopper === "date"}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        employee={employee}
        selectedScheduleProps={selectedScheduleProps}
        onClick={onNewDateSelect}
      />
    </>
  );
};

const SaveScheduleEditsPopper = ({
  activePopper,
  anchorEl,
  onPopperClick,
  onSaveCurrentSchedule,
  onSelectNewSchedule,
}) => {
  return (
    <Popper
      anchorEl={anchorEl}
      isOpen={activePopper === "save"}
      style={{ zIndex: 1300, maxWidth: "600px" }}
      title="Save Your Changes"
      onClose={onPopperClick}
      content={
        <Box p={2}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Typography variant="body1">
                Is this a change to the current commute schedule or would you
                like to create a new commute schedule based on the one you just
                edited?
              </Typography>
            </Grid>
            <Grid item container justifyContent="center" spacing={2}>
              <Grid item>
                <Tooltip title="Choose this option if this employee has't changed how they commute and you were instead fixing errors in your current commute schedule">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onSaveCurrentSchedule()}
                  >
                    Change my current schedule
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Choose this option if this employee recently changed how they commute. We'll ask you when this change occurred.">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onSelectNewSchedule()}
                  >
                    Create a new schedule
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};

const DisplayCommuteSchedulesView = ({ employee, setSelectedEmployee }) => {
  const [{ mostRecentAccountingDate }] = useAccountingData();

  const {
    name,
    commuteSchedules = [{}],
    vehicles: employeePersonalVehicles,
    id: employeeId,
    status,
    startDate: employeeStartDate,
  } = employee;

  const formatCommuteSchedulesWithTempIds = (commuteSchedules) =>
    commuteSchedules
      .map(({ commuteEndpoints, ...otherScheduleProps }) => {
        const commuteEndpointsWithTempIds = commuteEndpoints.map(
          (endpoint) => ({
            ...endpoint,
            tempId: generateTempId(),
          })
        );

        return {
          commuteEndpoints: commuteEndpointsWithTempIds,
          tempId: generateTempId(),
          ...otherScheduleProps,
        };
      })
      .reverse();

  const originalCommuteSchedulesWithTempIds =
    formatCommuteSchedulesWithTempIds(commuteSchedules);

  const originalSchedulesRef = useRef(originalCommuteSchedulesWithTempIds);

  const theme = useTheme();

  const [offices] = useOfficesWithCurrentChanges();
  const [companyVehicles] = useCachedDisplayData("vehicles");
  const [{ startDate: companyStartDate }, companyDataLoading] =
    useAccountData();

  const [formattedCommuteSchedules, setFormattedCommuteSchedules] = useState(
    []
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedScheduleProps, setSelectedScheduleProps] = useState({});
  const [selectedScheduleIdx, setSelectedScheduleIdx] = useState(
    commuteSchedules.length - 1
  );
  const [editMode, setEditMode] = useState(false);
  const [activePopper, setActivePopper] = useState(null);

  const { commuteEndpoints: originalCommuteEndpoints } =
    originalSchedulesRef.current[selectedScheduleIdx];
  const { commuteEndpoints, endDate, startDate, daysPerWk, tempId, home } =
    formattedCommuteSchedules[selectedScheduleIdx] || {};

  const doesEmployeeHaveMyAclymateDefaultSchedule =
    !!commuteEndpoints &&
    !!commuteEndpoints.find(
      ({ isMyAclymateDefaultEndpoint }) => isMyAclymateDefaultEndpoint
    );

  const formatCommuteSchedules = useCallback(
    (commuteSchedules) =>
      commuteSchedules.map(({ startDate, endDate, ...otherScheduleProps }) => ({
        ...otherScheduleProps,
        startDate: startDate || employeeStartDate || companyStartDate,
        endDate: endDate || new Date(),
      })),
    [companyStartDate, employeeStartDate]
  );

  useEffect(() => {
    if (!companyDataLoading && !formattedCommuteSchedules.length) {
      setFormattedCommuteSchedules(
        formatCommuteSchedules(originalSchedulesRef.current)
      );
    }
  }, [
    companyStartDate,
    companyDataLoading,
    commuteSchedules,
    formattedCommuteSchedules.length,
    formatCommuteSchedules,
  ]);

  const onPopperClick = (name, e) => {
    setActivePopper(!activePopper ? name : null);
    return setAnchorEl(anchorEl ? null : e?.currentTarget);
  };

  const editCommuteEndpoints = (newEndpoints) => {
    return editRowData(selectedScheduleIdx, setFormattedCommuteSchedules)(
      "commuteEndpoints",
      newEndpoints
    );
  };

  const editCommuteScheduleHome = (newHome) =>
    editRowData(selectedScheduleIdx, setFormattedCommuteSchedules)(
      "home",
      newHome
    );

  const allCommuteObjectsNotObsolete = (commuteEndpoints) => {
    if (!commuteEndpoints || !commuteEndpoints.length) {
      return false;
    }

    return commuteEndpoints.reduce((acc, { id, type, vehicles }) => {
      const findIsEndpointClosed = () => {
        if (type !== "companyOffice") {
          return false;
        }
        const { status } = offices.find((office) => office.id === id);

        return status === "closed";
      };

      const isEndpointClosed = findIsEndpointClosed();

      if (!isEndpointClosed && !vehicles) {
        return acc && true;
      }

      const allVehiclesNotClosed = vehicles
        ? vehicles.reduce((vehiclesAcc, { id: vehicleId, type }) => {
            if (
              [
                "carpool",
                "walkBike",
                "lightRail",
                "regionalRail",
                "intercityRail",
                "bus",
              ].includes(vehicleId || type)
            ) {
              return vehiclesAcc && true;
            }

            const dbVehicle = [
              ...companyVehicles,
              ...employeePersonalVehicles,
            ].find((vehicle) => vehicle.id === vehicleId);

            if (!dbVehicle) {
              return vehiclesAcc && false;
            }

            return vehiclesAcc && !dbVehicle?.archived;
          }, true)
        : true;

      return acc && !isEndpointClosed && allVehiclesNotClosed;
    }, true);
  };

  //TODO: CLEANUP- a lot of these functions look like they're duplicated in BuildWorkspacesForm. Maybe try to make them reusable.
  //ALSO: this function looks like it could easily do the same thing as editCommuteEndpoints
  const updatePeriodEndpoints = (updateFunction) =>
    setFormattedCommuteSchedules((currentSchedulesWithTempId) =>
      currentSchedulesWithTempId.map((scheduleWithTempId) => {
        if (tempId !== scheduleWithTempId.tempId) {
          return scheduleWithTempId;
        }
        const { commuteEndpoints } = scheduleWithTempId;
        return {
          ...scheduleWithTempId,
          commuteEndpoints: updateFunction(commuteEndpoints),
        };
      })
    );

  const updateDbCommuteSchedules = (newCommuteSchedules) => {
    const sortedCommuteSchedules = [...newCommuteSchedules]
      .sort((a, b) => {
        const { startDate: aStartDate } = a;
        const { startDate: bStartDate } = b;
        return (
          (bStartDate || companyStartDate) - (aStartDate || companyStartDate)
        );
      })
      .map(({ tempId, commuteEndpoints, ...otherScheduleProps }) => {
        const endpointsWithoutTempIds = commuteEndpoints.map(
          ({ tempId, daysPerWk, vehicles, ...otherEndpointProps }) => {
            if (!vehicles || !vehicles.length) {
              const defaultVehiclesArray = [
                {
                  daysPerWk,
                  id: "walkBike",
                  type: "walkBike",
                  name: "Walking/Biking",
                  tonsCo2ePerMile: 0,
                },
              ];

              return {
                ...otherEndpointProps,
                daysPerWk,
                vehicles: defaultVehiclesArray,
              };
            }

            return {
              ...otherEndpointProps,
              daysPerWk,
              vehicles,
            };
          }
        );

        return {
          commuteEndpoints: endpointsWithoutTempIds,
          ...otherScheduleProps,
        };
      });

    const accountId = window.sessionStorage.getItem("accountId");

    const newStatusObj = allCommuteObjectsNotObsolete(
      sortedCommuteSchedules[0]?.commuteEndpoints
    )
      ? { status: "confirmed" }
      : {};

    fetchOurApi({
      accountId,
      path: "/employees/update",
      method: "POST",
      data: {
        employeeId,
        updateObj: {
          commuteSchedules: sortedCommuteSchedules,
          ...newStatusObj,
        },
      },
    });

    originalSchedulesRef.current = formatCommuteSchedulesWithTempIds(
      sortedCommuteSchedules
    );
    editObjectData(
      setSelectedEmployee,
      "commuteSchedules",
      sortedCommuteSchedules
    );
    Object.keys(newStatusObj).length &&
      editObjectData(setSelectedEmployee, "status", "confirmed");
    setFormattedCommuteSchedules(
      formatCommuteSchedules(originalSchedulesRef.current)
    );
    setActivePopper(null);
    setAnchorEl(null);
    return setEditMode(false);
  };

  const formatDbSchedules = async () =>
    await Promise.all(
      formattedCommuteSchedules.map(async (commuteSchedule, idx) => {
        if (idx !== selectedScheduleIdx) {
          return commuteSchedule;
        }

        const {
          home,
          commuteEndpoints,
          tempId,
          startDate,
          endDate,
          ...otherCommuteScheduleFields
        } = commuteSchedule;

        const newCommuteEndpoints = await Promise.all(
          commuteEndpoints.map(async (endpoint) => {
            const {
              type,
              daysPerWk,
              address: endpointAddress,
              tempId,
              ...otherEndpointFields
            } = endpoint;
            const { address: homeAddress } = home;

            if (type === "homeOffice") {
              const eGrid = await fetchAddressEGrid(homeAddress);

              return {
                daysPerWk,
                type,
                eGrid,
              };
            }

            const newOneWayDistanceMi = await fetchDirectionsMileage(
              endpointAddress,
              homeAddress
            );

            if (type === "coworking" || type === "coffeeShop") {
              const { id, tempId, ...otherEndpointFields } = endpoint;

              return {
                ...otherEndpointFields,
                oneWayDistanceMi: newOneWayDistanceMi,
              };
            }

            return {
              ...otherEndpointFields,
              address: endpointAddress,
              daysPerWk,
              type,
              oneWayDistanceMi: newOneWayDistanceMi,
            };
          })
        );

        const newTotalCommuteDaysPerWk = newCommuteEndpoints.reduce(
          (sum, { daysPerWk }) => sum + daysPerWk,
          0
        );

        const dbEndDateObj = !dayjs(endDate).isSame(dayjs(), "day")
          ? { endDate: endDate }
          : {};
        const dbStartDateObj = !dayjs(startDate).isSame(
          dayjs(companyStartDate),
          "day"
        )
          ? { startDate: startDate }
          : {};

        return {
          ...otherCommuteScheduleFields,
          home,
          daysPerWk: newTotalCommuteDaysPerWk,
          commuteEndpoints: newCommuteEndpoints,
          ...dbEndDateObj,
          ...dbStartDateObj,
        };
      })
    );

  const onSaveCurrentSchedule = async () => {
    setAnchorEl(null);
    setActivePopper(null);
    const updatedSchedules = await formatDbSchedules();

    return updateDbCommuteSchedules(updatedSchedules);
  };

  const onCreateNewSchedule = async (date) => {
    const dbFormattedSchedules = await formatDbSchedules();
    const [newSchedule] = [...dbFormattedSchedules].reverse();
    const [currentSchedule, ...previousSchedules] = [
      ...originalSchedulesRef.current,
    ].reverse();

    const updatedSchedules = [
      { ...newSchedule, startDate: dayjs(date).toDate() },
      { ...currentSchedule, endDate: dayjs(date).subtract(1, "day").toDate() },
      ...previousSchedules,
    ];

    updateDbCommuteSchedules(updatedSchedules);
    return setAnchorEl(null);
  };

  const onSaveClick = async (e) => {
    if (
      mostRecentAccountingDate &&
      dayjs(mostRecentAccountingDate).isAfter(dayjs(startDate))
    ) {
      return onPopperClick("date-change", e);
    }

    if (
      status === "incomplete" ||
      selectedScheduleIdx !== formattedCommuteSchedules.length - 1
    ) {
      return onSaveCurrentSchedule();
    }

    return onPopperClick("save", e);
  };

  const onCommuteScheduleSelect = ({ e, tempId, endDate, idx }) => {
    onPopperClick("date", e);
    return setSelectedScheduleProps({
      tempId,
      endDate,
      idx,
      adjSchedule: formattedCommuteSchedules[idx + 1],
      prevSchedule: formattedCommuteSchedules[idx - 1],
    });
  };

  const onResetSchedule = () => {
    setFormattedCommuteSchedules(originalSchedulesRef.current);
    return setEditMode(false);
  };

  const isCommuteScheduleSame = () => {
    if (!originalCommuteEndpoints || !commuteEndpoints) {
      return false;
    }

    return commuteEndpoints.reduce(
      (acc, { id, name, vehicles, daysPerWk, type }) => {
        const findOriginalEndpoint = () => {
          if (type === "companyOffice") {
            return originalCommuteEndpoints.find(
              (endpoint) => endpoint.id === id
            );
          }

          if (type === "homeOffice") {
            return originalCommuteEndpoints.find(
              (endpoint) => endpoint.type === type
            );
          }

          return originalCommuteEndpoints.find(
            (endpoint) => endpoint.name === name
          );
        };

        const originalEndpoint = findOriginalEndpoint();

        if (!originalEndpoint) {
          return acc && false;
        }

        const { vehicles: originalVehicles, daysPerWk: originalDaysPerWk } =
          originalEndpoint;

        const areDaysPerWeekSame = daysPerWk === originalDaysPerWk;

        if (areDaysPerWeekSame && type === "homeOffice") {
          return acc && true;
        }

        const vehiclesReduce = vehicles
          ? vehicles.reduce(
              (vehiclesAcc, { id: vehicleId, daysPerWk: vehicleDaysPerWk }) => {
                const originalVehicle = originalVehicles.find(
                  (vehicle) => vehicle.id === vehicleId
                );

                if (!originalVehicle) {
                  return vehiclesAcc && false;
                }

                const { daysPerWk: originalVehicleDaysPerWk } = originalVehicle;

                return acc && vehicleDaysPerWk === originalVehicleDaysPerWk;
              },
              true
            )
          : true;

        return acc && areDaysPerWeekSame && vehiclesReduce;
      },
      true
    );
  };

  const workspaceCardActions = [
    editMode ? (
      <Tooltip title="Cancel Edits">
        <IconButton onClick={onResetSchedule} size="large">
          <CancelIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <></>
    ),
    editMode ? (
      <IconButton
        onClick={onSaveClick}
        disabled={
          isCommuteScheduleSame() ||
          !allCommuteObjectsNotObsolete(commuteEndpoints)
        }
        size="large"
      >
        <SaveIcon />
      </IconButton>
    ) : (
      <IconButton
        disabled={
          mostRecentAccountingDate &&
          dayjs(mostRecentAccountingDate).isAfter(dayjs(endDate))
        }
        onClick={() => setEditMode(true)}
        size="large"
      >
        <EditIcon />
      </IconButton>
    ),
  ];

  const onAvatarClick =
    ({ idx, isEditable, tempId, endDate }) =>
    (e) => {
      if (editMode && isEditable) {
        return onCommuteScheduleSelect({ e, tempId, endDate, idx });
      }
      return setSelectedScheduleIdx(idx);
    };

  return doesEmployeeHaveMyAclymateDefaultSchedule ? (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item>
        <Typography variant="body1" align="center">
          This employee's current commute schedule was created via MyAclymate
          and is incomplete. Send them a quick survey to create a new commute
          schedule.
        </Typography>
      </Grid>
      <Grid item>
        <SendEmployeeSurveyButton employee={employee} />
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          {commuteEndpoints && (
            <WorkspaceGraphicCard
              actions={workspaceCardActions}
              employeeName={name}
              endDate={endDate}
              startDate={startDate}
              daysPerWk={daysPerWk}
              graphic={
                <CommuteSchedulesInterface
                  commuteEndpoints={commuteEndpoints}
                  editCommuteEndpoints={editCommuteEndpoints}
                  updatePeriodEndpoints={updatePeriodEndpoints}
                  editCommuteScheduleHome={editCommuteScheduleHome}
                  editMode={editMode}
                  commuteHome={home}
                  employeePersonalVehicles={employeePersonalVehicles}
                />
              }
            />
          )}
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item sm={11} style={{ position: "relative" }}>
            <CommuteTimelineDateAvatar
              originalSchedulesWithTempIds={formattedCommuteSchedules}
              length={formattedCommuteSchedules.length}
              date={employeeStartDate || companyStartDate}
              position={{ left: "-20px" }}
              isActive={!selectedScheduleIdx}
              editMode={editMode}
              employee={employee}
              onClick={onAvatarClick({ idx: 0 })}
              clickDisabled={editMode}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
            <Box display="flex">
              {formattedCommuteSchedules.map(({ endDate, tempId }, idx) => (
                <Box
                  key={`commute-schedule-${idx}`}
                  position="relative"
                  style={{
                    width: `${100 / formattedCommuteSchedules.length}%`,
                  }}
                >
                  <ButtonBase
                    disabled={editMode}
                    key={`timeline-block-${idx}`}
                    style={{
                      height: theme.spacing(2),
                      width: "100%",
                      backgroundColor:
                        idx === selectedScheduleIdx
                          ? theme.palette.employees.main
                          : theme.palette.backgroundGray.dark,
                      position: "relative",
                    }}
                    onClick={() => setSelectedScheduleIdx(idx)}
                  />
                  <CommuteTimelineDateAvatar
                    originalSchedulesWithTempIds={formattedCommuteSchedules}
                    setCommuteSchedulesWithTempIds={
                      setFormattedCommuteSchedules
                    }
                    selectedScheduleProps={selectedScheduleProps}
                    employee={employee}
                    date={endDate}
                    clickDisabled={
                      editMode && idx === formattedCommuteSchedules.length - 1
                    }
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    onClick={onAvatarClick({
                      idx,
                      tempId,
                      endDate,
                      isEditable: idx !== formattedCommuteSchedules.length,
                    })}
                    isActive={
                      selectedScheduleIdx === idx ||
                      selectedScheduleIdx - 1 === idx
                    }
                    activePopper={activePopper}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <SaveScheduleEditsPopper
        anchorEl={anchorEl}
        activePopper={activePopper}
        onSelectNewSchedule={() => setActivePopper("date-change")}
        onSaveCurrentSchedule={onSaveCurrentSchedule}
        onPopperClick={onPopperClick}
      />
      <DatePopper
        employee={employee}
        setAnchorEl={onPopperClick}
        anchorEl={anchorEl}
        open={activePopper === "date-change"}
        selectedScheduleProps={{ prevSchedule: commuteSchedules[0] }}
        title="When did this change happen"
        onClick={onCreateNewSchedule}
      />
    </>
  );
};

const EmployeeCommuteSchedulesView = ({ employee, setSelectedEmployee }) => {
  const { commuteSchedules } = employee;

  if (!Array.isArray(commuteSchedules)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%", width: "100%" }}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="subtitle1" align="center">
              Looks like this employee doesn't have any commute schedules set
            </Typography>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item>
              <SendEmployeeSurveyButton employee={employee} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <DisplayCommuteSchedulesView
      employee={employee}
      setSelectedEmployee={setSelectedEmployee}
    />
  );
};
export default EmployeeCommuteSchedulesView;
