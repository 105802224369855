import React, { useState } from "react";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";

import TransactionDetailsBlock from "./TransactionDetailsBlock";

import { onUpdateTransactions } from "../../../helpers/components/emissions";
import { useOnVendorSave } from "../../../helpers/components/vendors";

const EditRowTransactions = ({
  transaction,
  setIsSlideOpen,
  setEmissions,
  ...otherProps
}) => {
  const { id, tonsCo2e: originalTonsCo2e } = transaction;

  const { onVendorSave } = useOnVendorSave();

  const [editableTransaction, setEditableTransaction] = useState(transaction);

  const editTransaction = (field) => (value) =>
    editObjectData(setEditableTransaction, field, value);

  const onRejectTransaction = async () => {
    setIsSlideOpen(null);

    await onUpdateTransactions([{ id, archived: true }]);

    return setEmissions((prevEmissions) =>
      prevEmissions.map((emission) =>
        emission.id === id ? { ...emission, archived: true } : emission
      )
    );
  };

  const onSaveTransaction = async (updateObj) => {
    const {
      knownVendor,
      subcategory,
      naicsCode,
      naicsTitle,
      tonsCo2ePerDollar,
      scopeThreeCategory,
      tonsCo2e: updateTonsCo2e,
    } = updateObj;

    if (knownVendor) {
      onVendorSave({
        ...knownVendor,
        emissionCategory: subcategory,
        naicsCode,
        naicsTitle,
        tonsCo2ePerDollar,
        scopeThreeCategory,
      });
    }

    if (setEmissions) {
      setEmissions((prevEmissions = []) =>
        prevEmissions.map((emission = {}) =>
          emission.id === id
            ? {
                ...emission,
                ...updateObj,
                tonsCo2e: updateTonsCo2e || originalTonsCo2e,
              }
            : emission
        )
      );
    }

    await onUpdateTransactions([
      { id, ...updateObj, tonsCo2e: updateTonsCo2e || originalTonsCo2e },
    ]);
    return setIsSlideOpen(null);
  };

  return (
    <TransactionDetailsBlock
      originalTransaction={transaction}
      transaction={editableTransaction}
      editTransaction={editTransaction}
      setIsSlideOpen={setIsSlideOpen}
      onRejectTransaction={onRejectTransaction}
      saveTransaction={onSaveTransaction}
      {...otherProps}
    />
  );
};

export default EditRowTransactions;
