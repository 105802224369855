import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { Button } from "@mui/material";

import { sumTonsCo2e } from "@aclymatepackages/other-helpers";
import {
  buildScopesRealDataObj,
  buildSubcategoriesDataObj,
  buildEmissionGroupData,
} from "@aclymatepackages/chart-helpers";
import { letterSBoolean } from "@aclymatepackages/formatters";

import {
  subcategories,
  otherSubcategoryObj,
} from "@aclymatepackages/subcategories";

import { scopeThreeSpendBasedCategories } from "./vendors";

import {
  addCollectionDoc,
  useAccountData,
  useCachedDisplayData,
  useCollectionDataListener,
} from "../firebase";
import { fetchOurApi } from "../utils/apiCalls";
import { getAccountCollectionAndId } from "../otherHelpers";
import useMeasurementSystem from "../hooks/measurementSystem";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);

export const usePrimaryViewAlerts = (setShowDuplicateTransactions) => {
  const [duplicateTransactionGroups] = useCachedDisplayData(
    "duplicate-transaction-groups"
  );
  const openDuplicateTransactionGroups = duplicateTransactionGroups.filter(
    ({ status }) => status === "open"
  );

  const duplicateTransactionsAlert = openDuplicateTransactionGroups.length
    ? [
        {
          title: `You have ${
            openDuplicateTransactionGroups.length
          } group${letterSBoolean(
            openDuplicateTransactionGroups
          )} of possibly duplicate transactions.`,
          subtitle: "Click to review and clean up these transactions",
          action: (
            <Button
              color="inherit"
              onClick={() => setShowDuplicateTransactions(true)}
            >
              View Duplicates
            </Button>
          ),
        },
      ]
    : [];

  return duplicateTransactionsAlert;
};

export const extractScopeThreeEmissions = (emissions) =>
  emissions.map((emission = {}) => {
    const { subcategory, scopeThreeCategory } = emission;
    if (subcategory !== "spend-based" || scopeThreeCategory < 0) {
      return emission;
    }

    const { subcategory: scopeThreeSubcategory } =
      scopeThreeSpendBasedCategories.find(
        ({ value }) => value === scopeThreeCategory
      ) || {};

    if (!scopeThreeSubcategory) {
      return emission;
    }

    return { ...emission, subcategory: scopeThreeSubcategory };
  });

export const splitAggregatedTransactionsBreakdownSubcategories = (
  transactionsMonths = []
) => {
  if (!transactionsMonths.length) {
    return [];
  }

  return transactionsMonths.flatMap(({ date, subcategoriesBreakdown = [] }) =>
    subcategoriesBreakdown.map(({ subcategory, ...otherProps }) => {
      const { scope } =
        subcategories.find(
          (subcategoryListObj) => subcategoryListObj.subcategory === subcategory
        ) || {};

      return {
        date,
        scope,
        subcategory,
        source: "aggregated",
        ...otherProps,
      };
    })
  );
};

export const useTransactionsMonthsData = () => {
  const [{ startDate }] = useAccountData();

  const [transactionsMonths = [], transactionsMonthsLoading] =
    useCollectionDataListener("all-aggregated-transactions", [
      ["date", ">=", startDate],
    ]);

  const formatTransactionsMonthsData = (transactionsMonths) => {
    if (!transactionsMonths.length) {
      return [];
    }

    return splitAggregatedTransactionsBreakdownSubcategories(
      transactionsMonths
    );
  };

  return [
    formatTransactionsMonthsData(transactionsMonths),
    transactionsMonthsLoading,
  ];
};

export const onNewTransaction = async (transaction) =>
  await addCollectionDoc("transactions", {
    ...transaction,
    isAggregated: false,
  });

export const onUpdateTransactions = async (transactionsToUpdate) => {
  const { id: companyId } = getAccountCollectionAndId();

  return await fetchOurApi({
    path: `/transactions/update-transactions`,
    method: "POST",
    data: {
      companyId,
      transactions: transactionsToUpdate,
    },
  });
};

const findObjectValuesSum = (object) =>
  Object.values(object).reduce((sum, value) => sum + value, 0);

const buildGroupedChartData = ({ groupedEmissions, viewMode }) => {
  const buildRealDataObj =
    viewMode === "subcategories"
      ? buildSubcategoriesDataObj
      : buildScopesRealDataObj;

  return groupedEmissions.map((emissionsArray, idx) => {
    const subcategoriesObj = buildRealDataObj(emissionsArray, [
      ...subcategories,
      otherSubcategoryObj,
    ]);

    const buildWarningObj = () => {
      const singleEmissionWarning = emissionsArray.find(
        ({ warning }) => !!warning
      );

      if (!singleEmissionWarning) {
        return null;
      }

      const { warning } = singleEmissionWarning;
      return warning;
    };

    return {
      ...subcategoriesObj,
      warning: buildWarningObj(),
      id: `emissions-chart-group-${idx}`,
      emissionsSumTons: sumTonsCo2e(emissionsArray),
      totalEmissionsSumTons: findObjectValuesSum(subcategoriesObj),
      viewMode,
    };
  });
};

export const useEmissionsChartData = ({
  emissions,
  viewMode = "subcategories",
  graphPeriod,
  branding,
  isPercentageChart,
}) => {
  const { convertCarbonUnits } = useMeasurementSystem();
  const [{ startDate }] = useAccountData();

  const {
    chartLabelsArray,
    scopesArray,
    subcategoriesArray,
    groupedEmissions,
  } = buildEmissionGroupData(emissions, graphPeriod, startDate, branding);

  const buildChartData = () => {
    const preliminaryChartData = buildGroupedChartData({
      groupedEmissions,
      viewMode,
    });

    const convertChartDataObject = (chartDataObj, converter) =>
      Object.fromEntries(
        Object.entries(chartDataObj).map(([key, value]) => {
          if (typeof value !== "number") {
            return [key, value];
          }
          return [key, converter(value)];
        })
      );

    const labelChartData = (chartData) =>
      chartData.map((data, idx) => ({
        ...data,
        label: chartLabelsArray[idx],
      }));

    if (isPercentageChart) {
      const percentageConvertedChartData = preliminaryChartData.map(
        (chartDataObj) => {
          const objectSubcategoryProperties = Object.keys(chartDataObj).filter(
            (key) =>
              subcategories.find(({ subcategory }) => subcategory === key)
          );
          const objectSubcategoryValues = objectSubcategoryProperties.map(
            (subcategory) => ({
              key: subcategory,
              value: chartDataObj[subcategory],
            })
          );

          const periodEmissionsSum = objectSubcategoryValues.reduce(
            (sum, { value }) => value + sum,
            0
          );

          const percentageConverter = (value) =>
            (value / periodEmissionsSum) * 100;

          const newObject = Object.fromEntries(
            objectSubcategoryValues.map(({ key, value }) => [key, value])
          );

          return convertChartDataObject(newObject, percentageConverter);
        }
      );
      return labelChartData(percentageConvertedChartData);
    }

    const unitConvertedChartData = preliminaryChartData.map((chartDataObj) =>
      convertChartDataObject(chartDataObj, convertCarbonUnits)
    );

    return labelChartData(unitConvertedChartData);
  };

  const chartData = buildChartData();

  const chartArray = viewMode === "scopes" ? scopesArray : subcategoriesArray;

  return { chartData, chartArray };
};
