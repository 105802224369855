import React, { useState } from "react";

import { Grid } from "@mui/material";

import { LoadingButton } from "@aclymatepackages/atoms";

import EmissionsDetailsTableAccordion from "../../modules/tables/EmissionsDetailsTableAccordion";

import { fetchOurApi } from "../../../helpers/utils/apiCalls";

const FetchEmissionsTableBlock = ({
  type,
  emissions,
  id,
  name,
  showCharts,
  setSelectedTransaction,
  closeSelectedObjectSlider,
  onAccordionClick,
}) => {
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const aggregatedEmissions =
    emissions && emissions.filter(({ source }) => source === "aggregated");

  if (!aggregatedEmissions?.length) {
    return (
      <EmissionsDetailsTableAccordion
        type={type}
        emissions={emissions}
        setSelectedTransaction={setSelectedTransaction}
        closeSelectedObjectSlider={closeSelectedObjectSlider}
        isExpanded={!showCharts}
        onChange={onAccordionClick}
        name={name}
      />
    );
  }

  const onFetchTaggedTransactions = async () => {
    setTransactionsLoading(true);
    const accountId = window.sessionStorage.getItem("accountId");
    return await fetchOurApi({
      path: "/transactions/fetch-account-tagged-transactions",
      method: "POST",
      data: { accountId, tag: type, tagId: id },
      callback: ({ transactions }) => {
        setTransactions(transactions);
        return setTransactionsLoading(false);
      },
    });
  };

  const nonAggregatedEmissions = emissions.filter(
    ({ source }) => source !== "aggregated"
  );

  if (!transactions.length) {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <LoadingButton
            isLoading={transactionsLoading}
            label="Fetch All Emissions"
            onClick={onFetchTaggedTransactions}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <EmissionsDetailsTableAccordion
      emissions={[...nonAggregatedEmissions, ...transactions]}
      setSelectedTransaction={setSelectedTransaction}
      closeSelectedObjectSlider={closeSelectedObjectSlider}
      type={type}
      isExpanded={!showCharts}
      onChange={onAccordionClick}
      name={name}
    />
  );
};
export default FetchEmissionsTableBlock;
