import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { usePDF } from "react-to-pdf";

import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  Avatar,
  ThemeProvider,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileChartLine } from "@fortawesome/pro-solid-svg-icons";

import { mergeDarkTheme } from "@aclymatepackages/themes";
import {
  buildEmissionsSummarySentence,
  sumTonsCo2e,
  filterEmissionsByScope,
} from "@aclymatepackages/other-helpers";
import { formatDate, formatDecimal } from "@aclymatepackages/formatters";
import {
  TextField,
  Backdrop,
  AnimatedLogo,
  CategoriesAvatar,
  Select,
} from "@aclymatepackages/atoms";
import searchSubcategories, {
  buildScopesWithColors,
} from "@aclymatepackages/subcategories";
import { EmissionsPieChart } from "@aclymatepackages/modules";
import { filterByDateRange } from "@aclymatepackages/date-helpers";
import { AclymateFooter } from "@aclymatepackages/net-zero";
import findSubcategory from "@aclymatepackages/subcategories";
import { getAccountCollectionAndId } from "../../../helpers/otherHelpers";

import ErrorBoundary from "../../atoms/ErrorBoundary";
import TableSkeleton from "../../atoms/loading/TableSkeleton";
import EditableContentBlock from "../../atoms/EditableContentBlock";
import EditableImageUploadDropzone from "../../atoms/EditableImageUploadDropzone";

import DateRangeFilter from "../../modules/filter/DateRangeFilter";
import DateIntervalToggles from "../../modules/DateIntervalToggles";
import LogoUploadDropzone from "../../modules/LogoUploadDropzone";
import EmissionsChart from "../../modules/charts/EmissionsChart";

import BadgeStepTab from "../badges/BadgeStepTab";

import EventPieChartTooltip from "../events/EventPieChartTooltip";

import ViewTitleActionLayout from "../../layouts/ViewTitleActionLayout";

import useEmissionsContext from "../../../helpers/contexts/emissions";
import {
  uploadFileToStorage,
  useAccountData,
  useCachedFirebaseCrud,
  useCollectionDataListener,
} from "../../../helpers/firebase";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";
import {
  createBasicDataForEventEmissionsPieChart,
  useDisplayEvents,
} from "../../../helpers/components/events";
import { splitAggregatedTransactionsBreakdownSubcategories } from "../../../helpers/components/emissions";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const PIXELS_PER_INCH = 96;
const PAGE_HEIGHT = PIXELS_PER_INCH * 11;

const DEFAULT_COMPANY_STATEMENT =
  "Share your company's mission and climate journey with the world. Enter your own text here.";

const filterEmissionsBySubcategory = (emissions, subcategory) =>
  emissions.filter((emission) => emission.subcategory === subcategory);

const findUniqueEmissionsSubcategories = (emissions) => [
  ...new Set(emissions.map(({ subcategory }) => subcategory)),
];

const scopes = [
  {
    scope: 1,
    description:
      "Scope 1 emissions include activities where your company is burning fossil fuels directly. This most often includes activities like gas utilities and vehicle fuel consumption.",
  },
  {
    scope: 2,
    description:
      "Scope 2 emissions include activities where someone is burning fossil fuels to provide your company energy in the form of electricity. These emissions are exclusively generated by your company's electric utility bills. ",
  },
  {
    scope: 3,
    description:
      "Scope 3 emissions are the vast category of emissions that cover all of the other activities that your company engages in where you aren't directly burning fossil fuels or consuming electricity",
  },
];

const convertImageCaller = (imageUrl, setImageString) =>
  fetchOurApi({
    path: "/helpers/convert-image",
    method: "POST",
    data: { imageUrl },
    callback: (res) => setImageString(res),
  });

const EmissionsSummarySentence = ({ label, emissions }) => (
  <Typography variant="body2">
    {buildEmissionsSummarySentence({ label, emissions })}
  </Typography>
);

const ReportGraphContentLayout = ({
  contentTitle,
  contentRows = [],
  chartType = "bar",
  chartViewMode = "scopes",
  emissions,
  interval = "quarter",
  isPercentageChart,
  height,
  branding,
  showTooltip,
}) => {
  return (
    <Box p={2} style={{ height: height || "auto" }}>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12} container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="subtitle1">{contentTitle}</Typography>
          </Grid>
          {contentRows.map((row, idx) => (
            <Grid item key={`content-row-${idx}`}>
              {row}
            </Grid>
          ))}
        </Grid>
        <Grid item md={8} xs={12}>
          <EmissionsChart
            dataArray={emissions}
            type={chartType}
            viewMode={chartViewMode}
            graphPeriod={interval}
            displayUnit="tons"
            aspect={2}
            isPercentageChart={isPercentageChart}
            showTooltip={showTooltip}
            branding={branding}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const ReportSummaryBlock = ({
  emissions,
  interval,
  branding,
  showTooltip,
  scopesWithColors,
}) => {
  const scopesWithEmissions = scopesWithColors.map((scopeObj) => {
    const { scope } = scopeObj;
    const scopeEmissions = filterEmissionsByScope(emissions, scope);
    return { ...scopeObj, emissions: scopeEmissions };
  });

  return (
    <ReportGraphContentLayout
      showTooltip={showTooltip}
      branding={branding}
      contentTitle={buildEmissionsSummarySentence({
        label: "Total Emissions",
        emissions,
      })}
      interval={interval}
      emissions={emissions}
      contentRows={[
        <EmissionsPieChart
          dataArray={emissions}
          viewMode="scopes"
          branding={branding}
          showTooltip={showTooltip}
        />,
        <Typography variant="subtitle1">Emissions Scope Breakdown</Typography>,
        ...scopesWithEmissions.map(({ scope, emissions, color }, idx) => (
          <EmissionsSummarySentence
            key={`summary-sentence-${idx}`}
            label={`Total Scope ${scope}`}
            emissions={emissions}
            scope={scope}
            color={color}
          />
        )),
      ]}
    />
  );
};

const TitleBlock = ({ name, startDate, endDate }) => {
  const { palette } = useTheme();

  const disclaimerTexts = [
    "Report prepared by Aclymate",
    `Report date: ${formatDate(dayjs())}`,
    `Reporting Period: ${formatDate(startDate)} - ${formatDate(endDate)}`,
  ];

  return (
    <Box p={1} style={{ backgroundColor: palette.backgroundGray.main }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <img
                src="/images/aclymate-black-logo.png"
                width={40}
                alt="black-aclymate-logo"
              />
            </Grid>
            <Grid item>
              <Typography variant="h4">Emissions report for {name}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {disclaimerTexts.map((text, idx) => (
            <Typography
              key={`disclaimer-text-${idx}`}
              variant="subtitle2"
              align="right"
            >
              {text}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

const EditableTextBlock = ({
  placeholder,
  textAlign = "left",
  variant = "body2",
  label,
  exportLoading = false,
}) => {
  const [customStatement, setCustomStatement] = useState("");
  const [editingStatement, setEditingStatement] = useState("");

  return (
    <Grid item>
      <EditableContentBlock
        exportLoading={exportLoading}
        onSave={() => setCustomStatement(editingStatement)}
        content={
          <Typography variant={variant} align={textAlign}>
            {customStatement || placeholder}
          </Typography>
        }
        editingInterface={
          <TextField
            label={label}
            value={editingStatement}
            setValue={setEditingStatement}
            multiline
            placeholder={placeholder}
          />
        }
      />
    </Grid>
  );
};

const CompanyStatemenBlock = ({ exportLoading }) => {
  return (
    <Box p={2}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h4" align="center">
            Emissions Report
          </Typography>
        </Grid>
        <EditableTextBlock
          label="Provide a custom statement for this report"
          placeholder={DEFAULT_COMPANY_STATEMENT}
          exportLoading={exportLoading}
        />
      </Grid>
    </Box>
  );
};

const ReportSectionBreak = ({ title }) => {
  const { palette } = useTheme();

  return (
    <Box p={2} style={{ backgroundColor: palette.backgroundGray.main }}>
      <Typography variant="h3" align="center">
        {title}
      </Typography>
    </Box>
  );
};

const ScopeEmissionsReport = ({ scope, description, emissions, interval }) => {
  const uniqueEmissionsSubcategories =
    findUniqueEmissionsSubcategories(emissions);

  return (
    <>
      <ReportGraphContentLayout
        contentTitle={`Scope ${scope} Emissions`}
        interval={interval}
        emissions={emissions}
        contentRows={[
          <EditableTextBlock
            placeholder={description}
            label={`Scope ${scope} summary`}
          />,
          <EmissionsSummarySentence
            label={`Total Scope ${scope} Emissions`}
            emissions={emissions}
          />,
        ]}
      />
      <ReportGraphContentLayout
        emissions={emissions}
        interval={interval}
        contentTitle={`Scope ${scope} emissions by category`}
        contentRows={uniqueEmissionsSubcategories.map((subcategory, idx) => (
          <EmissionsSummarySentence
            key={`scope-subcategory-summary-${idx}`}
            label={searchSubcategories(subcategory).name}
            emissions={filterEmissionsBySubcategory(emissions, subcategory)}
          />
        ))}
        chartType="area"
        chartViewMode="subcategories"
        isPercentageChart
      />
    </>
  );
};

const CompanyEmissionsReportView = ({ exportLoading, setExportLoading }) => {
  const { palette } = useTheme();

  const { allEmissions, emissionsLoading } = useEmissionsContext();

  const [{ branding, name, startDate }, companyDataLoading] = useAccountData();
  const { primaryColor, secondaryColor, logoUrl } = branding || {};

  const pageLoading = emissionsLoading || companyDataLoading;

  const [graphPeriod, setGraphPeriod] = useState("quarter");
  const [filterStartDate, setFilterStartDate] = useState(
    dayjs().startOf("year")
  );

  const [filterEndDate, setFilterEndDate] = useState(null);
  const [imageString, setImageString] = useState("");

  useEffect(() => {
    if (logoUrl) {
      convertImageCaller(logoUrl, setImageString);
    }
  }, [logoUrl]);

  const { targetRef, toPDF } = usePDF({
    filename: "aclymate-emission-report",
    page: { format: "letter" },
  });

  const onExport = async () => {
    setExportLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    toPDF();
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setExportLoading(false);
  };

  const dateFilteredEmissions = allEmissions.filter((emission) =>
    filterByDateRange({ filterEndDate, filterStartDate, ...emission })
  );

  const scopesWithColors = buildScopesWithColors(branding);

  return (
    <>
      <Grid item>
        <ViewTitleActionLayout
          viewLoading={emissionsLoading}
          avatar={
            <Avatar style={{ backgroundColor: palette.secondary.main }}>
              <FontAwesomeIcon icon={faFileChartLine} />
            </Avatar>
          }
          title="Custom Reporting"
          subtitle="Customize and export a report showing your emissions over a period of time"
          primaryAction={
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <DateRangeFilter
                  filterStartDate={filterStartDate}
                  setFilterStartDate={setFilterStartDate}
                  filterEndDate={filterEndDate}
                  setFilterEndDate={setFilterEndDate}
                  initialIntervalSelected="year-to-date"
                />
              </Grid>
              <Grid item>
                <DateIntervalToggles
                  graphPeriod={graphPeriod}
                  setGraphPeriod={setGraphPeriod}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onExport()}
                >
                  Export
                </Button>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item>
        {pageLoading ? (
          <TableSkeleton />
        ) : (
          <Grid container justifyContent="center">
            <Grid item>
              <Paper
                style={{
                  overflow: "hidden",
                  width: "8.5in",
                  position: "relative",
                }}
                ref={targetRef}
              >
                <Box style={{ height: exportLoading ? PAGE_HEIGHT : "auto" }}>
                  <TitleBlock
                    name={name}
                    startDate={filterStartDate || startDate}
                    endDate={filterEndDate || dayjs()}
                  />
                  <Box
                    p={4}
                    style={{
                      height: "150px",
                      background: `linear-gradient(30deg, ${
                        primaryColor || palette.primary.main
                      } 0%, ${secondaryColor || palette.secondary.main} 100%)`,
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ThemeProvider theme={mergeDarkTheme}>
                      <Typography
                        variant="h2"
                        align="center"
                        color="textPrimary"
                      >
                        Carbon Accounting Report for {name}
                      </Typography>
                    </ThemeProvider>
                  </Box>
                  <CompanyStatemenBlock exportLoading={exportLoading} />
                  <ReportSectionBreak title="Summary" />
                  <ReportSummaryBlock
                    emissions={dateFilteredEmissions}
                    interval={graphPeriod}
                    scopesWithColors={scopesWithColors}
                    branding={branding}
                  />
                </Box>
                {scopes.map(({ scope, description }, idx) => (
                  <Box
                    key={`scope-report-section-${idx + 1}`}
                    style={{
                      height: exportLoading ? PAGE_HEIGHT : "auto",
                    }}
                  >
                    <ReportSectionBreak title={`Scope ${scope} Emissions`} />
                    <ScopeEmissionsReport
                      emissions={filterEmissionsByScope(
                        dateFilteredEmissions,
                        scope
                      )}
                      interval={graphPeriod}
                      scope={scope}
                      description={description}
                    />
                  </Box>
                ))}
                <Box
                  p={2}
                  style={{ backgroundColor: palette.backgroundGray.main }}
                >
                  <Typography variant="body2" align="center">
                    This report has been prepared using Aclymate Inc by {name}{" "}
                    for the purpose of reporting company emissions. Aclymate
                    follows all GHG protocol guidelines and has generated this
                    report based on both actual and spend-based accounting
                    methodologies. All Scope Emissions in this report are based
                    on the information {name} has input.
                  </Typography>
                </Box>
                <Box
                  p={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: exportLoading ? 144 : "auto" }}
                >
                  <Grid
                    container
                    spacing={2}
                    alignItems="stretch"
                    justifyContent="space-around"
                  >
                    <Grid item sm={5}>
                      <img
                        alt="aclymate logo"
                        src="/images/aclymate-full-logo.png"
                        style={{ width: "100%", maxWidth: "600px" }}
                      />
                    </Grid>
                    <Grid item sm={5}>
                      {exportLoading ? (
                        <Grid container justifyContent="center">
                          <Grid item>
                            <img
                              alt="encoded company logo"
                              style={{ height: "74px" }}
                              src={`data:image/jpeg;base64, ${imageString}`}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <LogoUploadDropzone />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const EventEmissionsReportView = ({ exportLoading, setExportLoading }) => {
  const { palette, shape, spacing } = useTheme();
  const borderRadius = shape.borderRadius;

  const [eventAggregatedTransactions, transactionsLoading] =
    useCollectionDataListener("events-aggregated-transactions");

  const [events, eventsLoading] = useDisplayEvents();
  const { updateCollectionDoc } = useCachedFirebaseCrud();
  const [{ branding, name }, companyDataLoading] = useAccountData();

  const [selectedEventId, setSelectedEventId] = useState("");
  const [companyLogoImageString, setCompanyLogoImageString] = useState("");
  const [headerImageString, setHeaderImageString] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const { logoUrl } = branding || {};

  const eventOptions = events.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const pageLoading =
    transactionsLoading ||
    companyDataLoading ||
    eventsLoading ||
    !selectedEventId;

  const selectedEvent = events.find(({ id }) => id === selectedEventId);
  const {
    name: eventName,
    id: eventId,
    reportData,
    startDate,
    endDate,
    attendeeCount,
    tonsCo2e,
  } = selectedEvent || {};
  const { headerImage } = reportData || {};
  const defaultEventDescription = `${eventName} was held from ${formatDate(
    startDate
  )} to ${formatDate(
    endDate
  )}. There were ${attendeeCount} attendees present and the event created ${formatDecimal(
    tonsCo2e
  )} tonnes CO2e of emissions.`;

  useEffect(() => {
    if (!selectedEventId && !eventsLoading && events.length) {
      setSelectedEventId(events[0].id);
    }
  }, [selectedEventId, eventsLoading, events]);

  useEffect(() => {
    if (logoUrl) {
      convertImageCaller(logoUrl, setCompanyLogoImageString);
    }
  }, [logoUrl]);

  useEffect(() => {
    if (headerImage) {
      convertImageCaller(headerImage, setHeaderImageString);
    }
  }, [headerImage]);

  const { targetRef, toPDF } = usePDF({
    filename: "aclymate-emission-report",
    page: { format: "letter" },
  });

  const onExport = async () => {
    setExportLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    toPDF();
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setExportLoading(false);
  };

  const saveImage = async (image) => {
    const { id } = getAccountCollectionAndId();
    const imageUrl = await uploadFileToStorage({
      path: `${id}/event-${eventId}-report-header-image`,
      fileObject: image,
      hasUserConfirmedUpload: true,
    });

    return await updateCollectionDoc("events", eventId, {
      "reportData.headerImage": imageUrl,
    });
  };

  const { aggregatedTransactions = [] } =
    eventAggregatedTransactions.find(({ eventId: id }) => eventId === id) || {};

  const eventTransactions = splitAggregatedTransactionsBreakdownSubcategories(
    aggregatedTransactions
  );

  const groupedEventTransactionsBySubcategory = eventTransactions.reduce(
    (groupedTransactions, transaction) => {
      const { subcategory } = transaction;
      const allSubcategoryGroups = groupedTransactions.map(
        ({ subcategory }) => subcategory
      );
      const groupedTransactionsWithoutSubcategory = groupedTransactions.filter(
        ({ subcategory: groupSubcategory }) => groupSubcategory !== subcategory
      );
      const groupedTransactionsWithSubcategory = groupedTransactions.find(
        ({ subcategory: groupSubcategory }) => groupSubcategory === subcategory
      );

      if (allSubcategoryGroups.includes(subcategory)) {
        const { transactions: groupTransactions } =
          groupedTransactionsWithSubcategory;

        return [
          ...groupedTransactionsWithoutSubcategory,
          {
            ...groupedTransactionsWithSubcategory,
            transactions: [...groupTransactions, transaction],
          },
        ];
      }

      return [
        ...groupedTransactions,
        {
          subcategory,
          transactions: [transaction],
        },
      ];
    },
    []
  );
  const groupedEventTransactionsBySubcategoryTonsCo2e =
    groupedEventTransactionsBySubcategory.map(
      ({ subcategory, transactions }) => {
        const { name } = findSubcategory(subcategory);

        return {
          name: `Event Transactions (${name})`,
          subcategory,
          tonsCo2e: sumTonsCo2e(transactions),
        };
      }
    );

  const eventEmissionsDataWithoutEventTransactions =
    createBasicDataForEventEmissionsPieChart(selectedEvent);
  const eventEmissionsBreakdownData = [
    ...eventEmissionsDataWithoutEventTransactions,
    ...groupedEventTransactionsBySubcategoryTonsCo2e,
  ];
  const combinedPieChartData = [
    ...eventTransactions,
    ...eventEmissionsDataWithoutEventTransactions,
  ];

  return (
    <>
      <Grid item>
        <ViewTitleActionLayout
          viewLoading={pageLoading}
          avatar={
            <Avatar style={{ backgroundColor: palette.secondary.main }}>
              <FontAwesomeIcon icon={faFileChartLine} />
            </Avatar>
          }
          title="Event Reporting"
          subtitle="Select an event and export a report showing your event's emissions"
          primaryAction={
            <Grid container spacing={2} alignItems="center">
              <Grid item sx={{ width: "400px" }}>
                <Select
                  options={eventOptions}
                  value={selectedEventId}
                  editValue={setSelectedEventId}
                  size="small"
                  label="Select an event"
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onExport()}
                >
                  Export
                </Button>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item>
        {pageLoading ? (
          <TableSkeleton />
        ) : (
          <Grid container justifyContent="center">
            <Grid item>
              <Paper
                style={{
                  width: "8.5in",
                }}
                ref={targetRef}
              >
                <Box
                  display="flex"
                  sx={{
                    height: exportLoading ? PAGE_HEIGHT : "auto",
                    width: "100%",
                    flexFlow: "column",
                  }}
                >
                  {exportLoading ? (
                    <img
                      alt="encoded company logo"
                      style={{ height: "20rem", width: "100%" }}
                      src={`data:image/jpeg;base64, ${headerImageString}`}
                    />
                  ) : (
                    <EditableImageUploadDropzone
                      onSave={saveImage}
                      imageUrl={headerImage}
                      imageName="Header Image"
                      contentStyle={{
                        padding: spacing(6),
                        borderRadius: `${borderRadius}px ${borderRadius}px 0 0`,
                      }}
                      height="20rem"
                    />
                  )}
                  <Box p={2}>
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      alignItems="center"
                    >
                      <Grid
                        item
                        container
                        sx={{ position: "relative", height: "50px" }}
                      >
                        <Box
                          position="absolute"
                          display="flex"
                          sx={{
                            width: "200px",
                            height: "100px",
                            left: "calc(50% - 100px)",
                            top: "-50px",
                            marginBottom: spacing(4),
                            justifyContent: "center",
                          }}
                        >
                          {exportLoading ? (
                            <img
                              alt="encoded company logo"
                              style={{ height: "100px" }}
                              src={`data:image/jpeg;base64, ${companyLogoImageString}`}
                            />
                          ) : (
                            <Box
                              sx={{
                                border: `1px solid ${palette.backgroundGray.dark}`,
                                borderRadius: `${borderRadius}px`,
                                width: "100%",
                              }}
                            >
                              <LogoUploadDropzone
                                isEditing={isEditing}
                                setIsEditing={setIsEditing}
                                elementName="logo"
                              />
                            </Box>
                          )}
                        </Box>
                      </Grid>
                      <Grid item>
                        <Typography variant="h4" align="center">
                          {`${eventName} Emissions Report`}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ width: "100%" }}>
                        <EditableTextBlock
                          label="Provide a custom statement for this report"
                          placeholder={defaultEventDescription}
                          exportLoading={exportLoading}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    p={2}
                    sx={{
                      backgroundColor: palette.backgroundGray.main,
                      flexGrow: 1,
                    }}
                    display="flex"
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <EmissionsPieChart
                          dataArray={combinedPieChartData}
                          CustomTooltip={EventPieChartTooltip}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Box p={1} sx={{ backgroundColor: "white" }}>
                          <Grid container spacing={1} direction="column">
                            <Grid item>
                              <Typography variant="body1">
                                {`${eventName} Emissions Breakdown`}
                              </Typography>
                            </Grid>
                            <Grid item container direction="column" spacing={1}>
                              {eventEmissionsBreakdownData
                                .filter(({ tonsCo2e }) => tonsCo2e)
                                .map(({ name, tonsCo2e, subcategory }, key) => (
                                  <Grid
                                    item
                                    container
                                    wrap="nowrap"
                                    spacing={1}
                                    key={`event-emissions-breakdown-text-${key}`}
                                    alignItems="center"
                                  >
                                    <Grid item>
                                      <CategoriesAvatar
                                        subcategory={subcategory}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="body2">
                                        {`${name}: ${formatDecimal(
                                          tonsCo2e
                                        )} t`}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ))}
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <AclymateFooter companyName={name} isLocalImage />
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const ReportingBlock = () => {
  const { palette, spacing } = useTheme();

  const [exportLoading, setExportLoading] = useState(false);
  const [reportTypeTabIdx, setReportTypeTabIdx] = useState(0);

  const reportTypeTabs = [
    { name: "COMPANY", color: "primary" },
    { name: "EVENT", color: "events" },
  ];
  const tabsContent = [
    <CompanyEmissionsReportView
      exportLoading={exportLoading}
      setExportLoading={setExportLoading}
    />,
    <EventEmissionsReportView
      exportLoading={exportLoading}
      setExportLoading={setExportLoading}
    />,
  ];

  return (
    <>
      {exportLoading && (
        <Backdrop>
          <AnimatedLogo />
        </Backdrop>
      )}
      <Box
        style={{ height: "calc(100vh - 75px)", overflow: "hidden" }}
        display="flex"
        flexDirection="column"
      >
        <Grid container>
          {reportTypeTabs.map(({ name, color }, idx) => (
            <BadgeStepTab
              key={`badge-step-tab-${idx}`}
              name={name}
              color={palette[color]?.main}
              isSelected={reportTypeTabIdx === idx}
              onClick={() => setReportTypeTabIdx(idx)}
              gridItemXs={6}
            />
          ))}
        </Grid>
        <Box flexGrow={1} style={{ overflowY: "auto", padding: spacing(2) }}>
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ maxWidth: "100%" }}
          >
            {tabsContent[reportTypeTabIdx]}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

const Reporting = () => (
  <ErrorBoundary>
    <ReportingBlock />
  </ErrorBoundary>
);

export default Reporting;
