import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

import {
  Avatar,
  Grid,
  Slider,
  Typography,
  Tabs,
  Tab,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Container,
  IconButton,
  useTheme,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";

import { YesNoQuestion, PlacesAutocomplete } from "@aclymatepackages/modules";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import {
  Select,
  TextField,
  PlusMinusNumericInput,
} from "@aclymatepackages/atoms";
import { emailRegExpTest, numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { rentalCarCategories } from "@aclymatepackages/lists";
import mainTheme, { useLayoutHelpers } from "@aclymatepackages/themes";
import { MultiPartFormLayout } from "@aclymatepackages/multi-part-form";
import { formatDecimal } from "@aclymatepackages/formatters";

import { monthlyHomeOfficeUtilities } from "@aclymatepackages/calcs/recurring/defaultCalcs";

import CollapsibleAlert from "../atoms/notifications/CollapsibleAlert";
import ButtonCards from "../atoms/buttons/ButtonCards";
import VehicleMakeModelSelect from "../inputs/vehicles/VehicleMakeModelSelect";
import VehicleYearSelect from "../inputs/vehicles/VehicleYearSelect";
import FuelTypesSelect from "../inputs/vehicles/FuelTypesSelect";

import { analyticsTrack } from "../../helpers/analytics";
import { setAddress } from "../../helpers/utils/geography";
import { calcTonsCo2eForVehicleType } from "../../helpers/components/events";
import {
  fetchAddressEGrid,
  fetchOurApi,
  fetchNearestAirportFromCoordinates,
} from "../../helpers/utils/apiCalls";
import useMyAclymateSurveyCategories from "../../helpers/components/display-lists/myAclymateSurveyCategories";

const InfoAlert = ({ text }) => {
  const { palette } = useTheme();
  return (
    <CollapsibleAlert
      subtitle={text}
      backgroundColor={palette.backgroundGray.main}
      icon={<InfoIcon style={{ color: palette.text.primary }} />}
      color={palette.text.primary}
    />
  );
};

const GreenCheckButtonCards = ({
  options,
  setOption,
  selectedOption,
  gridItemProps,
  exclusive,
}) => (
  <ButtonCards
    centeredText
    options={options}
    setOption={setOption}
    selectedOption={selectedOption}
    color="#39b54a"
    gridItemProps={gridItemProps}
    exclusive={exclusive}
  />
);

const UtilityUsageInput = ({
  utilityUsage = {},
  availableHeatingTypes,
  utilityTypeKey,
  editIndividual,
}) => {
  const { value, unit, selectedCard } = utilityUsage[utilityTypeKey] || {};

  const {
    title: label,
    cardValues,
    defaultUnit,
  } = availableHeatingTypes.find(({ value }) => value === utilityTypeKey);
  const { belowAverage, average, aboveAverage } = cardValues;
  const timeScale = ["wood", "propane"].includes(utilityTypeKey)
    ? "year"
    : "month";

  const cardsOptions = [
    {
      title: "Below Average",
      subtitle: `${belowAverage} ${defaultUnit}/${timeScale}`,
      value: "belowAverage",
      usageValue: belowAverage,
    },
    {
      title: "Average",
      subtitle: `${average} ${defaultUnit}/${timeScale}`,
      value: "average",
      usageValue: average,
    },
    {
      title: "Above Average",
      subtitle: `${aboveAverage} ${defaultUnit}/${timeScale}`,
      value: "aboveAverage",
      usageValue: aboveAverage,
    },
    {
      title: "Custom",
      value: "custom",
      usageValue: 0,
    },
  ];

  const unitsList = {
    electricity: [{ label: "kWh / month", value: "kWh" }],
    naturalGas: [
      { label: "Therms / month", value: "therms" },
      { label: "ft\u00b3 / month", value: "cubicFeet" },
    ],
    propane: [{ label: "Gallons / year", value: "gallons" }],
    wood: [{ label: "Cords / year", value: "cords" }],
    heatingOil: [{ label: "Gallons / month", value: "gallons" }],
  };

  const saveNewUtilityUsage = (newUtilityTypeObj) =>
    editIndividual("utilityUsage")({
      ...utilityUsage,
      [utilityTypeKey]: newUtilityTypeObj,
    });

  const editUsageValue = (usageValue) =>
    saveNewUtilityUsage({
      value: Number(usageValue),
      unit,
      selectedCard: "custom",
    });

  const editUnitValue = (unitValue) =>
    saveNewUtilityUsage({ value, unit: unitValue, selectedCard: "custom" });

  return (
    <Grid item container spacing={1} justifyContent="center" direction="column">
      <Grid item>
        <Typography
          variant="body1"
          align="center"
          color="textPrimary"
        >{`${label} usage per ${timeScale}`}</Typography>
      </Grid>
      <Grid item>
        <GreenCheckButtonCards
          options={cardsOptions}
          setOption={(newValue) => {
            const { usageValue = 0 } =
              cardsOptions.find(({ value }) => value === newValue) || {};

            return saveNewUtilityUsage({
              value: usageValue,
              unit: defaultUnit,
              selectedCard: newValue,
            });
          }}
          selectedOption={selectedCard}
        />
      </Grid>
      {selectedCard === "custom" && (
        <Grid item container spacing={2}>
          <Grid item xs={8}>
            <TextField
              label={`${label} Usage`}
              value={value === 0 ? "0" : value}
              setValue={editUsageValue}
              helperText={
                (value < 0 || (!!value && !numbersRegExpTest(value))) &&
                "Please enter a non-negative number."
              }
              error={value < 0 || (!!value && !numbersRegExpTest(value))}
              variant="filled"
              id="utility-usage-input"
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              options={unitsList[utilityTypeKey]}
              label="Unit"
              value={unit}
              editValue={editUnitValue}
              size="small"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const UtilityUsageInterface = ({ individual = {}, editIndividual }) => {
  const {
    utilityUsage,
    electricityRenewablesPercentage,
    selectedHeatingTypes,
  } = individual;

  const availableHeatingTypes = [
    {
      value: "electricity",
      title: "Electricity",
      defaultUnit: "kWh",
      cardValues: {
        belowAverage: 459,
        average: 917,
        aboveAverage: 1834,
      },
    },
    {
      value: "naturalGas",
      title: "Natural Gas",
      defaultUnit: "therms",
      cardValues: {
        belowAverage: 33,
        average: 67,
        aboveAverage: 134,
      },
    },
    {
      value: "propane",
      title: "Propane",
      defaultUnit: "gallons",
      cardValues: {
        belowAverage: 221,
        average: 441,
        aboveAverage: 882,
      },
    },
    {
      value: "wood",
      title: "Wood",
      defaultUnit: "cords",
      cardValues: {
        belowAverage: 0.75,
        average: 1.5,
        aboveAverage: 3,
      },
    },
    {
      value: "heatingOil",
      title: "Heating Oil",
      defaultUnit: "gallons",
      cardValues: {
        belowAverage: 21,
        average: 41,
        aboveAverage: 82,
      },
    },
  ];

  return (
    <Grid container spacing={2} direction="column" alignItems="center">
      <UtilityUsageInput
        utilityUsage={utilityUsage}
        availableHeatingTypes={availableHeatingTypes}
        utilityTypeKey="electricity"
        editIndividual={editIndividual}
      />
      <Grid item container direction="column" alignItems="center" spacing={4}>
        <Grid item>
          <Typography variant="body1" align="center" color="textPrimary">
            What percentage of your electricity comes from renewable energy
            sources?
          </Typography>
        </Grid>
        <Grid item container style={{ width: "90%" }}>
          <Slider
            step={10}
            value={electricityRenewablesPercentage}
            onChange={(e, newValue) =>
              editIndividual("electricityRenewablesPercentage")(newValue)
            }
            marks={Array.from({ length: 11 }).map((val, idx) => {
              const markValue = idx * 10;

              if (!idx || idx === 10) {
                return {
                  value: markValue,
                  label: `${markValue}%`,
                };
              }

              return { value: markValue };
            })}
            valueLabelDisplay="on"
            valueLabelFormat={(selectedValue) => `${selectedValue}%`}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body1" align="center" color="textPrimary">
          How do you heat your home?
        </Typography>
      </Grid>
      <Grid item>
        <GreenCheckButtonCards
          options={availableHeatingTypes}
          setOption={editIndividual("selectedHeatingTypes")}
          selectedOption={selectedHeatingTypes}
          exclusive={false}
        />
      </Grid>
      {selectedHeatingTypes
        .filter((type) => type !== "electricity")
        .map((heatingType) => (
          <UtilityUsageInput
            utilityUsage={utilityUsage}
            availableHeatingTypes={availableHeatingTypes}
            utilityTypeKey={heatingType}
            editIndividual={editIndividual}
            key={heatingType}
          />
        ))}
    </Grid>
  );
};

const HouseholdMembersInterface = ({ individual, editIndividual }) => {
  const { isMobile } = useLayoutHelpers();

  const {
    selectedCalcType = "",
    numPeople,
    numRoommates,
    numDogs,
    numCats,
    numLargeDogs,
  } = individual;

  const cardsOptions = [
    {
      title: "Just me",
      value: "justMe",
    },
    {
      title: "Household",
      value: "household",
    },
  ];

  return (
    <Grid container alignItems="center" spacing={6} direction="column">
      <Grid item>
        <Typography
          variant="body1"
          color="textPrimary"
          align="center"
          paragraph
        >
          Would you like to calculate a footprint for just yourself or everyone
          in your household?
        </Typography>
        <InfoAlert
          text="Selecting household will account for emissions from the diet,
          household utilities, and financial expenses of the other people that
          live with you, including children."
        />
      </Grid>
      <Grid item>
        <GreenCheckButtonCards
          options={cardsOptions}
          setOption={(newValue) => {
            editIndividual("selectedCalcType")(newValue);
            if (newValue === "justMe") {
              editIndividual("numPeople")(1);
              editIndividual("domesticFlights")([2]);
              return editIndividual("internationalFlights")([0]);
            }

            editIndividual("domesticFlights")([2, 2]);
            editIndividual("internationalFlights")([0, 0]);
            editIndividual("numPeople")(2);
            return editIndividual("numRoommates")(0);
          }}
          selectedOption={selectedCalcType}
        />
      </Grid>
      {selectedCalcType && (
        <>
          <Grid item>
            <Typography variant="body1" color="textPrimary" align="center">
              Who lives in your household?
            </Typography>
            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              justifyContent="center"
              wrap={isMobile ? "wrap" : "nowrap"}
            >
              <Grid item>
                {selectedCalcType === "justMe" ? (
                  <PlusMinusNumericInput
                    value={numRoommates}
                    editValue={editIndividual("numRoommates")}
                    label="Roommates"
                    direction="column"
                  />
                ) : (
                  <PlusMinusNumericInput
                    minValue={2}
                    value={numPeople}
                    editValue={(newNumPeople) => {
                      const buildNewflightsArray = (numFlights) =>
                        [...new Array(newNumPeople)].map(() => numFlights);

                      editIndividual("domesticFlights")(
                        buildNewflightsArray(2)
                      );
                      editIndividual("internationalFlights")(
                        buildNewflightsArray(0)
                      );

                      return editIndividual("numPeople")(
                        newNumPeople >= 1 ? newNumPeople : 1
                      );
                    }}
                    label="People"
                    direction="column"
                  />
                )}
              </Grid>
              <Grid item>
                <PlusMinusNumericInput
                  value={numCats}
                  editValue={editIndividual("numCats")}
                  label="Cats"
                  direction="column"
                />
              </Grid>
              <Grid item>
                <PlusMinusNumericInput
                  value={numDogs}
                  editValue={editIndividual("numDogs")}
                  label="Dogs"
                  direction="column"
                />
              </Grid>
              <Grid item>
                <PlusMinusNumericInput
                  value={numLargeDogs}
                  editValue={editIndividual("numLargeDogs")}
                  label="Large Dogs"
                  direction="column"
                  annotation="(>50 lbs)"
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const useResidenceStepsRows = ({ individual, editIndividual }) => {
  const [monthlyHomeTonsCo2e, setMonthlyHomeTonsCo2e] = useState({
    monthlyElectricCarbonTons: 0,
    monthlyGasCarbonTons: 0,
    monthlyHeatingOilCarbonTons: 0,
    monthlyPropaneCarbonTons: 0,
    monthlyWoodCarbonTons: 0,
  });

  const {
    address,
    numPeople,
    numRoommates,
    numDogs,
    numCats,
    numLargeDogs,
    homeSqFootage,
    isUtilityUsageKnown,
    utilityUsage,
    electricityRenewablesPercentage,
    selectedHeatingTypes,
    eGrid,
  } = individual;
  const { state } = address || {};
  const {
    electricity: monthlyElectricityUsage,
    heatingOil: monthlyHeatingOilUsage,
    naturalGas: monthlyNaturalGasUsage,
    propane: yearlyPropaneUsage,
    wood: yearlyWoodUsage,
  } = utilityUsage;

  const isValidPets = [numDogs, numCats, numLargeDogs].reduce(
    (isValid, value) => isValid && numbersRegExpTest(value) && value >= 0,
    true
  );

  const generateTonsCo2eObjectFromSelectedHeatingTypes = () => {
    const monthlyHomeTonsCo2eObjWithRoommates = Object.keys(
      monthlyHomeTonsCo2e
    ).reduce((currentObj, homeTonsKey) => {
      const carbonTonsValue = monthlyHomeTonsCo2e[homeTonsKey];

      if (numRoommates >= 1) {
        return {
          ...currentObj,
          [homeTonsKey]: carbonTonsValue / (numRoommates + 1),
        };
      }

      return {
        ...currentObj,
        [homeTonsKey]: carbonTonsValue,
      };
    }, {});

    const {
      monthlyElectricCarbonTons = 0,
      monthlyGasCarbonTons = 0,
      monthlyHeatingOilCarbonTons = 0,
      monthlyPropaneCarbonTons = 0,
      monthlyWoodCarbonTons = 0,
    } = monthlyHomeTonsCo2eObjWithRoommates;

    if (!isUtilityUsageKnown) {
      return {
        monthlyElectricCarbonTons,
        monthlyGasCarbonTons,
      };
    }

    const selectedHeatingTypesTonsCo2eValues = {
      electricity: monthlyElectricCarbonTons,
      naturalGas: monthlyGasCarbonTons,
      heatingOil: monthlyHeatingOilCarbonTons,
      wood: monthlyWoodCarbonTons,
      propane: monthlyPropaneCarbonTons,
    };

    const selectedHeatingTypesTonsCo2eKeys = {
      electricity: "monthlyElectricCarbonTons",
      naturalGas: "monthlyGasCarbonTons",
      heatingOil: "monthlyHeatingOilCarbonTons",
      wood: "monthlyWoodCarbonTons",
      propane: "monthlyPropaneCarbonTons",
    };

    const tonsCo2eValuesObj = selectedHeatingTypes.reduce(
      (currentObj, heatingType) => {
        const heatingTypeTonsCo2eValue =
          selectedHeatingTypesTonsCo2eValues[heatingType];
        const heatingTypeTonsCo2eKey =
          selectedHeatingTypesTonsCo2eKeys[heatingType];

        return {
          ...currentObj,
          [heatingTypeTonsCo2eKey]: heatingTypeTonsCo2eValue,
        };
      },
      {}
    );

    return {
      monthlyElectricCarbonTons,
      ...tonsCo2eValuesObj,
    };
  };

  const handleRealtyMoleInfo = ({ sqFootage, realtyMolePropertyId }) => {
    editIndividual("homeSqFootage")(sqFootage);
    return editIndividual("realtyMolePropertyId")(realtyMolePropertyId);
  };

  const handleEditAddress = (value) => {
    if (!value) {
      return;
    }

    const { coordinates } = value || {};
    const { lat, lng } = coordinates || {};

    if (lat && lng) {
      fetchNearestAirportFromCoordinates(coordinates, (res) =>
        editIndividual("defaultAirport")(res)
      );
    }

    fetchAddressEGrid(value).then((eGrid) => editIndividual("eGrid")(eGrid));
    return editIndividual("address")(value);
  };

  useEffect(() => {
    if (!isUtilityUsageKnown && state && eGrid && homeSqFootage) {
      const { monthlyGasCarbonTons, monthlyElectricCarbonTons } =
        monthlyHomeOfficeUtilities({
          homeSqFootage,
          eGrid,
          state,
          electricRenewablesPercentage: electricityRenewablesPercentage,
        });

      setMonthlyHomeTonsCo2e({
        monthlyGasCarbonTons,
        monthlyElectricCarbonTons,
      });
    }
  }, [
    isUtilityUsageKnown,
    state,
    eGrid,
    homeSqFootage,
    electricityRenewablesPercentage,
  ]);

  useEffect(() => {
    if (isUtilityUsageKnown) {
      fetchOurApi({
        path: "/calcs/individuals/monthly-home-carbon-from-utilities-usage",
        method: "POST",
        data: {
          monthlyElectricityUsage,
          monthlyNaturalGasUsage,
          yearlyPropaneUsage,
          yearlyWoodUsage,
          monthlyHeatingOilUsage,
          address,
        },
        callback: ({ monthlyElectricCarbonTons, ...otherCarbonTons }) => {
          const monthlyElectricCarbonTonsWithRenewables =
            monthlyElectricCarbonTons -
            monthlyElectricCarbonTons * (electricityRenewablesPercentage / 100);

          return setMonthlyHomeTonsCo2e({
            monthlyElectricCarbonTons: monthlyElectricCarbonTonsWithRenewables,
            ...otherCarbonTons,
          });
        },
      });
    }
  }, [
    isUtilityUsageKnown,
    monthlyElectricityUsage,
    monthlyHeatingOilUsage,
    monthlyNaturalGasUsage,
    yearlyPropaneUsage,
    yearlyWoodUsage,
    address,
    electricityRenewablesPercentage,
  ]);

  const buildUtilityUsageInterfaceRow = isUtilityUsageKnown
    ? [
        {
          value: true,
          input: (
            <UtilityUsageInterface
              individual={individual}
              editIndividual={editIndividual}
            />
          ),
        },
      ]
    : [];

  const residenceRows = [
    {
      NextStepInput: PlacesAutocomplete,
      nextStepInputProps: {
        place: address,
        onInputSelect: setAddress(handleEditAddress, handleRealtyMoleInfo),
        inputSelectPropName: "editPlace",
        size: "small",
        label: "What is your home address?",
        helperText: "We only need your physical address, not your suite number",
        id: "home-address-autocomplete",
      },
      value: address,
    },
    {
      value: isValidPets && numPeople >= 1,
      input: (
        <HouseholdMembersInterface
          individual={individual}
          editIndividual={editIndividual}
        />
      ),
    },
    {
      label: "What is the size of your home?",
      value: homeSqFootage === 0 ? "0" : homeSqFootage,
      helperText:
        homeSqFootage <= 0 ||
        (!!homeSqFootage && !numbersRegExpTest(homeSqFootage))
          ? "Please enter a positive number."
          : "Please enter a square footage.",
      editData: editIndividual("homeSqFootage"),
      error:
        homeSqFootage <= 0 ||
        (!!homeSqFootage && !numbersRegExpTest(homeSqFootage)),
    },
    {
      NextStepInput: YesNoQuestion,
      nextStepInputProps: {
        lightBackground: true,
        question:
          "Do you have info on your household's monthly electricity and heating usage?",
        onInputSelect: editIndividual("isUtilityUsageKnown"),
        inputSelectPropName: "setValue",
        value: isUtilityUsageKnown,
      },
      value: isUtilityUsageKnown !== undefined,
    },
    ...buildUtilityUsageInterfaceRow,
  ];

  return {
    residenceSteps: residenceRows.map((row) => ({ ...row, form: "residence" })),
    monthlyHomeTonsCo2e: generateTonsCo2eObjectFromSelectedHeatingTypes(),
  };
};

const VehicleInput = ({
  vehicle,
  editVehicle,
  address,
  vehiclesCount,
  deleteVehicle,
}) => {
  const theme = useTheme();

  const [availableFuelTypes, setAvailableFuelTypes] = useState([]);

  const {
    make,
    model,
    year,
    fuelType,
    vehicleType = null,
    yearlyVehicleMileageValue,
    selectedVehicleMileageCard,
    selectedVehicleFormType,
  } = vehicle;

  const vehicleTypeOptions = rentalCarCategories.map(({ name, type }) => ({
    label: name,
    value: type,
  }));

  const vehicleMileageOptions = [
    {
      value: "belowAverage",
      label: "Below Average",
      secondaryLabel: "6,378 miles/year",
      mileage: 6738,
    },
    {
      value: "average",
      label: "Average",
      secondaryLabel: "13,476 miles/year",
      mileage: 13476,
    },
    {
      value: "aboveAverage",
      label: "Above Average",
      secondaryLabel: "26,952 miles/year",
      mileage: 26952,
    },
    {
      value: "custom",
      label: "Custom",
      mileage: 0,
    },
  ];

  const editVehicleType = (newVehicleType) => {
    const tonsCo2ePerMile = calcTonsCo2eForVehicleType(newVehicleType);

    return editVehicle({ tonsCo2ePerMile, vehicleType: newVehicleType });
  };

  const getTailpipeEmissionsCall = (vehicle) =>
    fetchOurApi({
      path: "/calcs/cars/tailpipe-emissions",
      method: "POST",
      data: {
        vehicle,
        address,
      },
      callback: ({ vehicleCarbonTonsPerMile }) => vehicleCarbonTonsPerMile || 0,
    });

  const onEditVehicleMakeModelYear = (field) => async (value) => {
    const clearModelInput =
      field === "make"
        ? {
            model: "",
          }
        : {};

    const newVehicleObj = {
      ...vehicle,
      [field]: value,
      ...clearModelInput,
    };

    const {
      make: newMake,
      model: newModel,
      year: newYear,
      fuelType: newFuelType = "gas",
      vehicleType,
    } = newVehicleObj;

    if (newMake && newModel && newYear && newFuelType) {
      const tonsCo2ePerMile = await getTailpipeEmissionsCall(newVehicleObj);

      return editVehicle({
        ...newVehicleObj,
        tonsCo2ePerMile,
      });
    }

    const onFetchFuelTypesCallback = async (fuelTypesArray) => {
      if (fuelTypesArray.length === 1) {
        const [fuelType] = fuelTypesArray;
        const tonsCo2ePerMile = await getTailpipeEmissionsCall(newVehicleObj);

        return editVehicle({
          ...newVehicleObj,
          fuelType,
          tonsCo2ePerMile,
        });
      }

      editVehicle(newVehicleObj);
      return setAvailableFuelTypes(fuelTypesArray);
    };

    if (newMake && newModel && newYear && !vehicleType) {
      return fetchOurApi({
        path: "/cars/available-fuel-types",
        method: "POST",
        data: { make: newMake, model: newModel, year: newYear },
        callback: onFetchFuelTypesCallback,
      });
    }

    return editVehicle(newVehicleObj);
  };

  const handleSetSelectedOption = (newOption) => {
    const { mileage = 0 } =
      vehicleMileageOptions.find(({ value }) => value === newOption) || {};

    return editVehicle({
      yearlyVehicleMileageValue: mileage,
      selectedVehicleMileageCard: newOption,
    });
  };

  const YearlyMilesSelect = ({ size }) => (
    <Select
      label="Miles driven/year"
      options={vehicleMileageOptions}
      editValue={handleSetSelectedOption}
      value={selectedVehicleMileageCard}
      size={size}
    />
  );

  return (
    <Grid item>
      <Box
        p={2}
        sx={{
          border: `thick ${theme.palette.backgroundGray.main} solid`,
          borderRadius: theme.spacing(2),
        }}
        position="relative"
      >
        {vehiclesCount > 1 && (
          <IconButton
            sx={{
              position: "absolute",
              top: `-${theme.spacing(3)}`,
              right: `-${theme.spacing(3)}`,
            }}
            onClick={deleteVehicle}
          >
            <Avatar
              sx={{
                backgroundColor: theme.palette.backgroundGray.main,
                color: theme.palette.text.primary,
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Avatar>
          </IconButton>
        )}
        <Grid container direction="column" spacing={2}>
          <Grid item container justifyContent="center">
            <Grid item>
              <Tabs
                value={selectedVehicleFormType}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, newValue) =>
                  editVehicle({ selectedVehicleFormType: newValue })
                }
              >
                <Tab label="Vehicle Type" value="vehicleType" />
                <Tab label="Make/Model/Year" value="makeModelYear" />
              </Tabs>
            </Grid>
          </Grid>
          {selectedVehicleFormType === "vehicleType" ? (
            <>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  align="center"
                >
                  Hint: for the most accurate results, select Make/Model/Year
                </Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item sm={7} xs={12}>
                  <Select
                    options={vehicleTypeOptions}
                    label="Vehicle Type"
                    value={vehicleType || ""}
                    editValue={editVehicleType}
                  />
                </Grid>
                <Grid item sm={5} xs={12}>
                  <YearlyMilesSelect />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <VehicleMakeModelSelect
                  type="makes"
                  vehicleValue={make}
                  editValue={onEditVehicleMakeModelYear("make")}
                />
              </Grid>
              <Grid item>
                <VehicleMakeModelSelect
                  type="models"
                  vehicleValue={model}
                  editValue={onEditVehicleMakeModelYear("model")}
                  make={make}
                />
              </Grid>
              <Grid item>
                <VehicleYearSelect
                  year={year}
                  editVehicle={onEditVehicleMakeModelYear("year")}
                  noLabel={false}
                  smallFont
                />
              </Grid>
              {availableFuelTypes.length > 1 && (
                <Grid item style={{ width: "100%" }}>
                  <FuelTypesSelect
                    availableFuelTypes={availableFuelTypes}
                    fuelType={fuelType}
                    editVehicle={onEditVehicleMakeModelYear("fuelType")}
                  />
                </Grid>
              )}
              <Grid item>
                <YearlyMilesSelect size="small" />
              </Grid>
            </>
          )}
          {selectedVehicleMileageCard === "custom" && (
            <Grid item>
              <TextField
                label="Miles driven per year"
                value={
                  yearlyVehicleMileageValue === 0
                    ? "0"
                    : yearlyVehicleMileageValue
                }
                setValue={onEditVehicleMakeModelYear(
                  "yearlyVehicleMileageValue"
                )}
                helperText={
                  yearlyVehicleMileageValue < 0 ||
                  (!!yearlyVehicleMileageValue &&
                    !numbersRegExpTest(yearlyVehicleMileageValue))
                    ? "Please enter a non-negative number."
                    : "Please enter the number of miles you drive per year"
                }
                error={
                  yearlyVehicleMileageValue < 0 ||
                  (!!yearlyVehicleMileageValue &&
                    !numbersRegExpTest(yearlyVehicleMileageValue))
                }
                id="custom-vehicle-mileage-input"
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

const useTravelStepsRows = ({ individual, editIndividual }) => {
  const [homeWorkMonthlyTonsCo2e, setHomeWorkMonthlyTonsCo2e] = useState({});
  const [officeOneWayDistanceMi, setOfficeOneWayDistanceMi] = useState(0);

  const {
    vehicles = [],
    doYouHaveVehicles,
    selectedOtherTransportation = [],
    workType,
    officeWorkAddress,
    homeSqFootage,
    homeOfficeSqFootage,
    officeDaysPerWk = 0,
    homeDaysPerWk = 0,
    address,
    eGrid,
    electricityRenewablesPercentage,
  } = individual;

  const isAllVehicleInputsComplete = vehicles.reduce(
    (isValid, { tonsCo2ePerMile }) => isValid && !!tonsCo2ePerMile,
    true
  );

  const editVehicle = (idx) => (updateObj) => {
    const updatedVehicles = vehicles.map((vehicle, vehicleIdx) =>
      vehicleIdx === idx ? { ...vehicle, ...updateObj } : vehicle
    );

    return editIndividual("vehicles")(updatedVehicles);
  };

  const addAnotherVehicle = () =>
    editIndividual("vehicles")([
      ...vehicles,
      {
        yearlyVehicleMileageValue: 13476,
        selectedVehicleMileageCard: "average",
        selectedVehicleFormType: "vehicleType",
      },
    ]);

  const deleteVehicle = (index) => () => {
    const updatedVehicles = vehicles.filter((_, idx) => idx !== index);
    return editIndividual("vehicles")(updatedVehicles);
  };

  const buildVehicleInputsRow = doYouHaveVehicles
    ? [
        {
          value: isAllVehicleInputsComplete,
          input: (
            <Grid item container spacing={2} direction="column">
              <Grid item>
                <InfoAlert
                  text={`Be sure to use the "add vehicle" button to add all the vehicles your/your household owns and fill out the corresponding information for each one.`}
                />
              </Grid>
              {vehicles.map((vehicle, idx) => (
                <VehicleInput
                  key={`vehicle-input-${idx}`}
                  address={address}
                  vehiclesCount={vehicles.length}
                  vehicle={vehicle}
                  editVehicle={editVehicle(idx)}
                  deleteVehicle={deleteVehicle(idx)}
                />
              ))}
              <Grid item container justifyContent="center">
                <Grid item>
                  <Button
                    color="primary"
                    sx={{ textDecoration: "underline" }}
                    onClick={() => addAnotherVehicle()}
                  >
                    + Add Another Vehicle
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ),
        },
      ]
    : [];

  const otherTransportation = [
    {
      value: "bus",
      title: "Bus",
      averageMonthlyMileage: 480,
    },
    {
      value: "lightRail",
      title: "Light Rail/Subway",
      averageMonthlyMileage: 320,
    },
    {
      value: "regionalRail",
      title: "Commuter Rail",
      averageMonthlyMileage: 224,
    },
    {
      value: "intercityRail",
      title: "Amtrak",
      averageMonthlyMileage: 320,
    },
    {
      value: "carpool",
      title: "Carpooling",
      averageMonthlyMileage: 400,
    },
    {
      value: "walkBike",
      title: "Walk/Bike",
      averageMonthlyMileage: 80,
    },
  ];

  const isAllCommuteInputsComplete = () => {
    const isOfficeFieldsComplete = !!officeWorkAddress && officeDaysPerWk > 0;
    const isHomeFieldsComplete = homeSqFootage > 0 && homeDaysPerWk > 0;

    if (workType === "office") {
      return isOfficeFieldsComplete;
    }

    if (workType === "home") {
      return isHomeFieldsComplete;
    }

    return isOfficeFieldsComplete && isHomeFieldsComplete;
  };

  const availableWorkTypes = [
    { value: "office", title: "Office" },
    { value: "hybrid", title: "Hybrid" },
    { value: "home", title: "Home" },
  ];

  useEffect(() => {
    const fetchOfficeOneWayMileage = async () => {
      const { totalMileage: oneWayMileage } = await fetchOurApi({
        path: "/calcs/cars/directions-mileage",
        method: "POST",
        data: {
          to: officeWorkAddress,
          from: address,
        },
        callback: (res) => res,
      });

      return setOfficeOneWayDistanceMi(oneWayMileage);
    };

    if (officeWorkAddress && officeDaysPerWk >= 0) {
      fetchOfficeOneWayMileage();
    }
  }, [officeDaysPerWk, officeWorkAddress, address]);

  useEffect(() => {
    if (homeOfficeSqFootage && homeDaysPerWk >= 0) {
      const { monthlyGasCarbonTons, monthlyElectricCarbonTons } =
        monthlyHomeOfficeUtilities({
          ...address,
          homeSqFootage,
          homeOfficeSqFootage,
          eGrid,
          electricRenewablesPercentage: electricityRenewablesPercentage,
        });

      setHomeWorkMonthlyTonsCo2e(
        monthlyGasCarbonTons + monthlyElectricCarbonTons
      );
    }
  }, [
    homeDaysPerWk,
    homeOfficeSqFootage,
    homeSqFootage,
    address,
    electricityRenewablesPercentage,
    eGrid,
  ]);

  const travelRows = [
    {
      NextStepInput: YesNoQuestion,
      nextStepInputProps: {
        lightBackground: true,
        question: "Do you own a vehicle?",
        onInputSelect: editIndividual("doYouHaveVehicles"),
        inputSelectPropName: "setValue",
        value: doYouHaveVehicles,
      },
      value: doYouHaveVehicles !== undefined,
    },
    ...buildVehicleInputsRow,
    {
      value: true,
      input: (
        <Grid container spacing={2} justifyContent="center" direction="column">
          <Grid item>
            <Typography variant="body1" color="textPrimary" align="center">
              Select the other forms of transportation that you use to get
              around each month.
            </Typography>
          </Grid>
          <Grid item>
            <GreenCheckButtonCards
              options={otherTransportation}
              setOption={editIndividual("selectedOtherTransportation")}
              selectedOption={selectedOtherTransportation}
              exclusive={false}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      value: isAllCommuteInputsComplete(),
      input: (
        <Grid container spacing={2} alignItems="center" direction="column">
          <Grid item>
            <Typography variant="body1" color="textPrimary">
              Where do you work in a typical week?
            </Typography>
          </Grid>
          <Grid container item>
            <GreenCheckButtonCards
              options={availableWorkTypes}
              setOption={editIndividual("workType")}
              selectedOption={workType}
            />
          </Grid>
          <Grid item container spacing={2} justifyContent="center">
            {(workType === "office" || workType === "hybrid") && (
              <Grid item sm={6} xs={12}>
                <PlacesAutocomplete
                  place={officeWorkAddress}
                  editPlace={setAddress((value) =>
                    editIndividual("officeWorkAddress")(value)
                  )}
                  size="small"
                  label="What is your office address?"
                  helperText="We only need the physical address, not the suite number"
                  textFieldProps={{ variant: "filled" }}
                  id="office-address-autocomplete"
                />
              </Grid>
            )}
            {(workType === "home" || workType === "hybrid") && (
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Home office square footage"
                  value={homeOfficeSqFootage === 0 ? "0" : homeOfficeSqFootage}
                  setValue={editIndividual("homeOfficeSqFootage")}
                  helperText={
                    homeOfficeSqFootage < 0 ||
                    (!!homeOfficeSqFootage &&
                      !numbersRegExpTest(homeOfficeSqFootage))
                      ? "Please enter a non-negative number."
                      : "Enter the square footage of your home office."
                  }
                  error={
                    homeOfficeSqFootage < 0 ||
                    (!!homeOfficeSqFootage &&
                      !numbersRegExpTest(homeOfficeSqFootage))
                  }
                  id="home-office-sq-footage-input"
                />
              </Grid>
            )}
          </Grid>
          <Grid item container spacing={2} justifyContent="center">
            {(workType === "office" || workType === "hybrid") &&
              officeWorkAddress && (
                <Grid item sm={6} xs={12}>
                  <PlusMinusNumericInput
                    value={officeDaysPerWk}
                    editValue={editIndividual("officeDaysPerWk")}
                    label="Days per week @ office"
                    minValue={0}
                    maxValue={workType === "hybrid" ? 7 - homeDaysPerWk : 7}
                  />
                </Grid>
              )}
            {(workType === "home" || workType === "hybrid") &&
              homeOfficeSqFootage && (
                <Grid item sm={6} xs={12}>
                  <PlusMinusNumericInput
                    value={homeDaysPerWk}
                    editValue={editIndividual("homeDaysPerWk")}
                    label="Days per week @ home"
                    minValue={0}
                    maxValue={workType === "hybrid" ? 7 - officeDaysPerWk : 7}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
      ),
    },
  ];

  return {
    travelSteps: travelRows.map((row) => ({ ...row, form: "travel" })),
    homeWorkMonthlyTonsCo2e,
    officeOneWayDistanceMi,
  };
};

const FlightInputRow = ({ type, numPeople, value, editFlights }) => (
  <>
    <Typography variant="body1" color="textPrimary" align="center" paragraph>
      # of roundtrip <span style={{ fontWeight: 900 }}>{type}</span> flights
    </Typography>
    <Grid container spacing={1} justifyContent="center">
      {[...new Array(numPeople)].map((_, idx) => (
        <Grid item key={`domestic-flight-${idx}`}>
          <PlusMinusNumericInput
            value={value[idx]}
            editValue={editFlights(`${type}Flights`, idx)}
            label={!idx ? "You" : `Person ${idx}`}
            direction="column"
          />
        </Grid>
      ))}
    </Grid>
  </>
);

const useFlightsStepsRows = ({ individual, editIndividual }) => {
  const { numPeople, domesticFlights, internationalFlights } = individual;

  const editFlights = (flightType, idx) => (numFlights) => {
    const updatedFlights = individual[flightType].map((flight, flightIdx) =>
      flightIdx === idx ? numFlights : flight
    );

    return editIndividual(flightType)(updatedFlights);
  };

  return {
    flightsSteps: [
      {
        value: true,
        input: (
          <Grid container spacing={4} direction="column">
            <Grid item>
              <InfoAlert
                text={`Please enter the number of round trip flights ${
                  numPeople === 1 ? "you take" : "your household takes"
                } per year. Please include business travel unless your company has an Aclymate account.`}
              />
            </Grid>
            <Grid item>
              <FlightInputRow
                type="domestic"
                numPeople={numPeople}
                value={domesticFlights}
                editFlights={editFlights}
              />
            </Grid>
            <Grid item>
              <FlightInputRow
                type="international"
                numPeople={numPeople}
                value={internationalFlights}
                editFlights={editFlights}
              />
            </Grid>
          </Grid>
        ),
        form: "flights",
      },
    ],
  };
};

const useDietStepsRows = ({ individual, editIndividual }) => {
  const { dietType } = individual;

  const dietOptions = [
    {
      title: "Standard American Diet",
      value: "sad",
      qualities: [
        "High in red/processed meats",
        "Moderate to low in fruits & vegetables",
        "Low in whole grains and high in refined grains",
      ],
    },
    {
      title: "Less Beef, More Leaf",
      value: "had-1",
      qualities: [
        "1/2 red/processed meats",
        "2x fruits & vegetables",
        "4x whole grains and 1/3 refined grains",
      ],
    },
    {
      title: "Healthy Omnivore",
      value: "had-2",
      qualities: [
        "1/4 red/processed meats",
        "2x fruits & vegetables",
        "4x whole grains and 1/3 refined grains",
      ],
    },
    {
      title: "Vegetarian/Vegan",
      value: "had-3",
      qualities: [
        "No red/processed meats",
        "2.5x fruits & vegetables",
        "4x whole grains and 1/3 refined grains",
      ],
    },
  ];

  const { qualities: currentDietQualities, title: currentDietTitle } =
    dietOptions.find(({ value }) => value === dietType) || {};

  return {
    dietSteps: [
      {
        value: true,
        input: (
          <Grid
            container
            justifyContent="center"
            spacing={2}
            direction="column"
          >
            <Grid item>
              <GreenCheckButtonCards
                options={dietOptions}
                setOption={editIndividual("dietType")}
                selectedOption={dietType}
              />
            </Grid>
            {!!currentDietQualities && (
              <Grid item container justifyContent="center">
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    {currentDietTitle}
                  </Typography>
                  {currentDietQualities.map((quality, idx) => (
                    <Typography
                      variant="body2"
                      key={`diet-quality-${idx}`}
                      color="textPrimary"
                    >
                      - {quality}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        ),
        form: "diet",
      },
    ],
  };
};

const AnnualExpenditureCategoryCard = ({
  category,
  individual,
  uneditedAnnualExpenditures,
  editExpendituresObj,
}) => {
  const { annualExpenditures, numPeople } = individual;
  const {
    annualHouseholdExpenditures,
    annualPerCapitaExpenditures,
    selectedExpenditureCard = "",
  } = annualExpenditures[category];
  const {
    annualHouseholdExpenditures: uneditedAnnualHouseholdExpenditures,
    annualPerCapitaExpenditures: uneditedAnnualPerCapitaExpenditures,
  } = uneditedAnnualExpenditures[category];
  const annualExpenditureValue =
    numPeople > 1 ? annualHouseholdExpenditures : annualPerCapitaExpenditures;
  const uneditedAnnualExpenditureValue =
    numPeople > 1
      ? uneditedAnnualHouseholdExpenditures
      : uneditedAnnualPerCapitaExpenditures;

  const perMonthExpenditureCategories = ["manufacturedProducts", "services"];
  const isCategoryDisplayedPerMonth =
    perMonthExpenditureCategories.includes(category);
  const expenditureValueWithTimeframe = isCategoryDisplayedPerMonth
    ? uneditedAnnualExpenditureValue / 12
    : uneditedAnnualExpenditureValue;
  const timeframe = isCategoryDisplayedPerMonth ? "month" : "year";

  const categoryLabelsAndSubtitles = {
    clothing: {
      title: "Clothing",
      subtitle: "Clothing, footwear, and other apparel services.",
    },
    manufacturedProducts: {
      title: "Manufactured Products",
      subtitle:
        "Housekeeping supplies, furniture, appliances, personal care products, etc.",
    },
    services: {
      title: "Services",
      subtitle: "Utilities, telephone bills, health insurance, education, etc.",
    },
  };
  const { title, subtitle } = categoryLabelsAndSubtitles[category];

  const expenditureCategoryOptions = [
    {
      title: "Below Average",
      subtitle: `$${formatDecimal(
        expenditureValueWithTimeframe / 2
      )}/${timeframe}`,
      value: "belowAverage",
      expenditureValue: uneditedAnnualExpenditureValue / 2,
    },
    {
      title: "Average",
      subtitle: `$${formatDecimal(expenditureValueWithTimeframe)}/${timeframe}`,
      value: "average",
      expenditureValue: uneditedAnnualExpenditureValue,
    },
    {
      title: "Above Average",
      subtitle: `$${formatDecimal(
        expenditureValueWithTimeframe * 2
      )}/${timeframe}`,
      value: "aboveAverage",
      expenditureValue: uneditedAnnualExpenditureValue * 2,
    },
    {
      title: "Custom",
      value: "custom",
      expenditureValue: 0,
    },
  ];

  const generateNewAnnualExpendituresObj = (
    newAnnualExpenditures,
    otherValues = {}
  ) => {
    const valueKey =
      numPeople > 1
        ? "annualHouseholdExpenditures"
        : "annualPerCapitaExpenditures";

    return {
      ...annualExpenditures,
      [category]: {
        ...annualExpenditures[category],
        [valueKey]: Number(newAnnualExpenditures),
        ...otherValues,
      },
    };
  };

  const editAnnualExpenditures = (newAnnualExpenditures) => {
    if (isNaN(newAnnualExpenditures)) {
      return null;
    }

    return editExpendituresObj(
      generateNewAnnualExpendituresObj(Number(newAnnualExpenditures))
    );
  };

  const handleSetExpenditureOption = (newValue) => {
    console.log("new value: ", newValue);
    const { expenditureValue } =
      expenditureCategoryOptions.find(({ value }) => value === newValue) || {};

    return editExpendituresObj(
      generateNewAnnualExpendituresObj(expenditureValue, {
        selectedExpenditureCard: newValue,
      })
    );
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item container alignItems="center" direction="column">
        <Grid item>
          <Typography variant="body1" color="textPrimary">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center" variant="subtitle2" color="textSecondary">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <GreenCheckButtonCards
          options={expenditureCategoryOptions}
          selectedOption={selectedExpenditureCard}
          setOption={handleSetExpenditureOption}
        />
      </Grid>
      {selectedExpenditureCard === "custom" && (
        <Grid item>
          <TextField
            label={`$ per ${timeframe}`}
            value={
              annualExpenditureValue === 0
                ? "0"
                : isCategoryDisplayedPerMonth
                ? annualExpenditureValue / 12
                : annualExpenditureValue
            }
            setValue={(newValue) =>
              isCategoryDisplayedPerMonth
                ? editAnnualExpenditures(newValue * 12)
                : editAnnualExpenditures(newValue)
            }
            helperText={
              (annualExpenditureValue < 0 ||
                (!!annualExpenditureValue &&
                  !numbersRegExpTest(annualExpenditureValue))) &&
              "Please enter a non-negative number."
            }
            error={
              annualExpenditureValue < 0 ||
              (!!annualExpenditureValue &&
                !numbersRegExpTest(annualExpenditureValue))
            }
            variant="filled"
            id="annual-expenditures-input"
          />
        </Grid>
      )}
    </Grid>
  );
};

const useSpendingStepsRows = ({ individual, editIndividual }) => {
  const [uneditedAnnualExpenditures, setUneditedAnnualExpenditures] = useState(
    {}
  );

  const { annualHouseholdIncome, annualExpenditures, numPeople } = individual;

  const incomeSliderMarks = Array.from({ length: 31 }).map((val, idx) => {
    const markValue = idx * 10000;

    if (!idx || idx === 30) {
      return {
        value: markValue,
        label: `$${Math.trunc(markValue / 1000)}k`,
      };
    }

    return { value: markValue };
  });

  const editExpendituresObj = (newExpendituresObj) =>
    editIndividual("annualExpenditures")(newExpendituresObj);

  const expenditureCardInputs =
    annualExpenditures && uneditedAnnualExpenditures
      ? Object.keys(annualExpenditures).map((categoryKey) => {
          const individualExpenditureObj = annualExpenditures[categoryKey];
          const {
            selectedExpenditureCard,
            annualHouseholdExpenditures,
            annualPerCapitaExpenditures,
          } = individualExpenditureObj;
          return {
            value:
              selectedExpenditureCard &&
              (numPeople > 1
                ? annualHouseholdExpenditures
                : annualPerCapitaExpenditures),
            input: (
              <AnnualExpenditureCategoryCard
                category={categoryKey}
                individual={individual}
                uneditedAnnualExpenditures={uneditedAnnualExpenditures}
                editExpendituresObj={editExpendituresObj}
              />
            ),
            form: "spending",
            submitButtonText: "Next Step",
          };
        })
      : [];

  const handleSliderOnChange = async (newValue) => {
    editIndividual("annualHouseholdIncome")(newValue);

    const annualExpenditures = await fetchOurApi({
      path: "/calcs/individuals/expenditures-from-annual-household-income",
      method: "POST",
      data: {
        annualHouseholdIncome: newValue,
      },
      callback: (res) => res,
    });

    setUneditedAnnualExpenditures(annualExpenditures);
    return editIndividual("annualExpenditures")(annualExpenditures);
  };

  return {
    spendingSteps: [
      {
        value: annualHouseholdIncome,
        input: (
          <Grid container spacing={4} direction="column" alignItems="center">
            <Grid item>
              <Typography
                variant="body1"
                color="textPrimary"
                align="center"
                paragraph
              >
                Select the value that is closest to your annual household income
              </Typography>
              <InfoAlert text="This information is used to estimate your spending in the following categories. Your information will not be shared." />
            </Grid>
            <Grid item container justifyContent="center">
              <Box
                display="flex"
                p={2}
                style={{ width: "85%", height: "100%" }}
              >
                <Slider
                  step={null}
                  value={annualHouseholdIncome}
                  onChange={(e, newValue) => handleSliderOnChange(newValue)}
                  marks={incomeSliderMarks}
                  min={0}
                  max={300000}
                  valueLabelDisplay="on"
                  valueLabelFormat={(selectedValue) => {
                    if (!selectedValue) {
                      return "$0";
                    }
                    if (selectedValue === 300000) {
                      return ">= $300k";
                    }

                    return `$${Math.trunc(selectedValue / 1000)}k`;
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        ),
        form: "spending",
      },
      ...expenditureCardInputs,
    ],
  };
};

const useEmailStep = ({ individual, editIndividual, onSurveyFinish }) => {
  const { email, name, optIntoMarketingEmails } = individual;

  return [
    {
      value: true,
      nextStepDisabled: !(name && email && emailRegExpTest(email)),
      input: (
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item container>
            <TextField
              value={name}
              setValue={editIndividual("name")}
              label="What's your first and last name?"
            />
          </Grid>
          <Grid item container>
            <TextField
              value={email}
              setValue={editIndividual("email")}
              label="Email address"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={optIntoMarketingEmails}
                  onChange={() =>
                    editIndividual("optIntoMarketingEmails")(
                      !optIntoMarketingEmails
                    )
                  }
                />
              }
              label={
                <Typography variant="body2" color="textPrimary">
                  I want to receive fun and educational climate info
                  periodically
                </Typography>
              }
            />
          </Grid>
        </Grid>
      ),
      form: "email",
      submitButtonText: "See My Results",
      onNextStep: onSurveyFinish,
    },
  ];
};

const SurveyIntroScreen = ({ setShowSurvey }) => (
  <Box>
    <Typography variant="h4" color="secondary" align="center" gutterBottom>
      myAclymate
    </Typography>
    <Typography variant="h1" paragraph align="center">
      Carbon Footprint Calculator
    </Typography>
    <Typography variant="body1" paragraph align="center">
      Calculate your personal carbon footprint in minutes with our fast and easy
      carbon footprint calculator. In this survey we will ask you a series of
      questions to help determine the most accurate estimate of your footprint.
      At the end, you will receive an estimate of your carbon footprint, a
      detailed breakdown of your areas of impact, and options to reduce or
      offset your footprint.
    </Typography>
    <Typography variant="body2" color="textSecondary" paragraph align="center">
      The information you provide is secure and only used to calculate your
      carbon footprint.
    </Typography>
    <Grid container pt={2} justifyContent="center">
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowSurvey(true)}
        >
          Begin Survey
        </Button>
      </Grid>
    </Grid>
  </Box>
);

const ProgressBar = ({ formStep }) => {
  const { isMobile } = useLayoutHelpers();
  const theme = useTheme();

  const steps = useMyAclymateSurveyCategories({});

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.backgroundGray.main,
        borderRadius: "20px",
      }}
      display="flex"
    >
      {steps.map(({ label, icon, color }, idx) => (
        <Box
          key={`progress-bar-${idx}`}
          py={1}
          px={2}
          sx={{
            borderRadius: "20px",
            backgroundColor: idx === formStep ? color : "transparent",
            width: "100%",
          }}
          display="flex"
          justifyContent="center"
        >
          {!isMobile ? (
            <Typography
              variant="body2"
              align="center"
              style={{ color: idx === formStep ? "white" : "inherit" }}
            >
              {label}
            </Typography>
          ) : (
            <FontAwesomeIcon
              icon={icon}
              style={{ color: idx === formStep ? "white" : "inherit" }}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

const MyAclymateSurvey = ({
  setIndividualData,
  existingEmail,
  existingIndividualId,
}) => {
  const [, setCookie] = useCookies();
  const { theme } = useLayoutHelpers();

  const [formStep, setFormStep] = useState(0);
  const [showSurvey, setShowSurvey] = useState(false);
  const [individual, setIndividual] = useState({
    numPeople: 0,
    numRoommates: 0,
    numDogs: 0,
    numCats: 0,
    numLargeDogs: 0,
    electricityRenewablesPercentage: 0,
    selectedHeatingTypes: [],
    utilityUsage: {
      electricity: {
        value: 917,
        unit: "kWh",
        selectedCard: "average",
      },
      naturalGas: {
        value: 67,
        unit: "therms",
        selectedCard: "average",
      },
      propane: {
        value: 441,
        unit: "gallons",
        selectedCard: "average",
      },
      wood: {
        value: 1.5,
        unit: "cords",
        selectedCard: "average",
      },
      heatingOil: {
        value: 41,
        unit: "gallons",
        selectedCard: "average",
      },
    },
    vehicles: [
      {
        yearlyVehicleMileageValue: 13476,
        selectedVehicleMileageCard: "average",
        selectedVehicleFormType: "vehicleType",
      },
    ],
    dietType: "sad",
    annualHouseholdIncome: 0,
    optIntoMarketingEmails: true,
    email: existingEmail,
  });

  const editIndividual = (field) => (value) =>
    editObjectData(setIndividual, field, value);

  const { residenceSteps, monthlyHomeTonsCo2e } = useResidenceStepsRows({
    individual,
    editIndividual,
  });

  const { travelSteps, homeWorkMonthlyTonsCo2e, officeOneWayDistanceMi } =
    useTravelStepsRows({
      individual,
      editIndividual,
    });
  const { flightsSteps } = useFlightsStepsRows({
    individual,
    editIndividual,
  });
  const { dietSteps } = useDietStepsRows({
    individual,
    editIndividual,
  });

  const onSurveyFinish = async () => {
    const {
      individualId,
      individual: individualData,
      doesAclymateAccountAlreadyExist,
    } = await fetchOurApi({
      path: "/individuals/myaclymate-survey-submit",
      method: "POST",
      data: {
        individual,
        individualId: existingIndividualId,
        monthlyHomeTonsCo2e,
        homeWorkMonthlyTonsCo2e,
        officeOneWayDistanceMi,
      },
      callback: (res) => res,
    });

    if (!individualId) {
      return null;
    }

    const { email, slug, optIntoMarketingEmails } = individualData;

    setCookie("aclymate-in-slug", slug);
    window.sessionStorage.setItem("accountId", `individuals-${individualId}`);

    if (optIntoMarketingEmails) {
      analyticsTrack("myAclymate Opt in", { email });
    }

    analyticsTrack("myAclymate Survey Submit", { email });

    return setIndividualData((currentIndividualData) => ({
      id: individualId,
      ...currentIndividualData,
      ...individualData,
      doesAclymateAccountAlreadyExist,
    }));
  };

  const { spendingSteps } = useSpendingStepsRows({
    individual,
    editIndividual,
  });

  const emailStep = useEmailStep({
    individual,
    editIndividual,
    onSurveyFinish,
  });

  const emailForm = !existingEmail
    ? [
        {
          name: "email",
          label: "Email",
          title: "You've Completed the Survey!",
          subtitle:
            "Enter your name and email below to view your free detailed carbon footprint report.",
        },
      ]
    : [];

  const emailSteps = !existingEmail ? emailStep : [];

  const individualSurveyForms = [
    {
      name: "residence",
      label: "Home",
      title: "Let's start with home sweet home",
    },
    {
      name: "travel",
      label: "Travel",
      title: "Next, how do you get around?",
    },
    {
      name: "flights",
      label: "Flights",
      title: "How many flights do you take in a typical year?",
    },
    {
      name: "diet",
      label: "Diet",
      title: "What is your diet on an average day?",
    },
    {
      name: "spending",
      label: "Spending",
      title: "What are your expenses?",
    },
    ...emailForm,
  ];

  const individualSurveyRows = [
    ...residenceSteps,
    ...travelSteps,
    ...flightsSteps,
    ...dietSteps,
    ...spendingSteps,
    ...emailSteps,
  ];

  return (
    <>
      <Container maxWidth="md" style={{ height: "100%" }}>
        <Box display="flex" flexDirection="column" style={{ height: "100%" }}>
          <Box flexGrow={0}>
            {showSurvey && <ProgressBar formStep={formStep} />}
          </Box>
          <Box
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ overflowY: "auto" }}
            pt={4}
          >
            {!showSurvey ? (
              <SurveyIntroScreen setShowSurvey={setShowSurvey} />
            ) : (
              <MultiPartFormLayout
                color={theme.palette.secondary.main}
                forms={individualSurveyForms}
                rows={individualSurveyRows}
                confirmClose={false}
                theme={mainTheme}
                activeFormStep={formStep}
                setActiveFormStep={setFormStep}
                allowFormScrolling
              />
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
};
export default MyAclymateSurvey;
