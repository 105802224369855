import React, { useState } from "react";

import { Grid, Typography, Divider } from "@mui/material";

import { lbsToTons, kgsToTons } from "@aclymatepackages/converters";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { TextField, ToggleButtons } from "@aclymatepackages/atoms";
import { calcShippingEmissionsTons } from "@aclymatepackages/calcs/travel";

import DistanceInputOptions from "../DistanceInputOptions";

import {
  buildInitialTransactionInputValueFromSchema,
  findDistanceMiles,
} from "../../../helpers/components/inputs";
import useMeasurementSystem from "../../../helpers/hooks/measurementSystem";

const transportationOptions = [
  { name: "Truck", value: "road" },
  { name: "Ship", value: "sea" },
  { name: "Air", value: "air" },
  { name: "Train", value: "rail" },
];

const shippingDirections = [
  {
    value: "downstream",
    name: "Downstream",
    annotation:
      "Downstream shipping is any shipping of a product down from you to your customers. You can think of this as your distribution.",
  },
  {
    value: "upstream",
    name: "Upstream",
    annotation:
      "Upstream shipping is any shipping from one of your vendors down to you. You can think of this as your supply chain.",
  },
];

const ShippingInput = ({
  emissionData,
  editEmissionData,
  setCalcLoading,
  setCalculatedMileage,
}) => {
  const { measurementSystem } = useMeasurementSystem();

  const { transportationMethod, distanceTraveled, shippingWeight, direction } =
    emissionData;

  const weightUnit = measurementSystem === "imperial" ? "lbs" : "kgs";
  const onSetWeight = (value) => {
    editEmissionData("shippingWeight")(value);
    return editEmissionData("weightUnit")(weightUnit);
  };

  return (
    <Grid container spacing={2} direction="column" wrap="nowrap">
      <Grid item>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          How far did your shipment travel?
        </Typography>
        <DistanceInputOptions
          type="shipping"
          inputData={emissionData}
          editInputData={editEmissionData}
          oldDbMileageValue={distanceTraveled}
          setCalcLoading={setCalcLoading}
          setCalculatedMileage={setCalculatedMileage}
        />
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          What is the total shipping weight?
        </Typography>
        <TextField
          setValue={onSetWeight}
          value={shippingWeight}
          label={`Shipping Weight (${weightUnit})`}
          error={
            (shippingWeight && !numbersRegExpTest(shippingWeight)) ||
            shippingWeight < 0
          }
        />
      </Grid>
      <Grid item>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Shipping method and direction
        </Typography>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <ToggleButtons
              buttons={transportationOptions}
              value={transportationMethod}
              onChange={editEmissionData("transportationMethod")}
            />
          </Grid>
          <Grid item>
            <ToggleButtons
              buttons={shippingDirections}
              value={direction}
              onChange={editEmissionData("direction")}
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

/*SCHEMA
{
  weightUnit: string,
  distanceUnit: string,
  transportationMethod: string,
  distanceTraveled: string,
  shippingWeight: number,
  startPoint: addressSchema,
  endPoint: addressSchema,
  direction: "downtstream" | "upstream",
}
*/
const useShippingInput = ({ transaction, onSave, setCalcLoading }) => {
  const inputSchema = [
    { field: "weightUnit", defaultValue: "lbs" },
    { field: "distanceUnit", defaultValue: "mi" },
    { field: "transportationMethod" },
    { field: "distanceTraveled" },
    { field: "shippingWeight" },
    { field: "startPoint" },
    { field: "endPoint" },
    { field: "direction", defaultValue: "downstream" },
  ];

  const [calculatedMileage, setCalculatedMileage] = useState(0);
  const [emissionData, setEmissionData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );

  const editEmissionData = (field) => (value) =>
    editObjectData(setEmissionData, field, value);

  const {
    transportationMethod,
    distanceTraveled,
    shippingWeight,
    direction,
    weightUnit,
    distance,
    distanceUnit,
  } = emissionData;

  const calcShippingTons = () => {
    const shippingDistanceInMiles = findDistanceMiles(
      { distance: distance || distanceTraveled, distanceUnit },
      calculatedMileage
    );

    if (!shippingWeight || !shippingDistanceInMiles || !transportationMethod) {
      return 0;
    }

    const shippingWeightInTons =
      weightUnit === "lbs"
        ? lbsToTons(shippingWeight)
        : weightUnit === "kgs"
        ? kgsToTons(shippingWeight)
        : shippingWeight;

    return calcShippingEmissionsTons(
      shippingDistanceInMiles,
      shippingWeightInTons,
      transportationMethod
    );
  };

  const tonsCo2e = calcShippingTons();

  const scopeThreeCategory = direction === "downstream" ? 9 : 4;

  const onTransactionSave = () =>
    onSave({
      ...emissionData,
      tonsCo2e,
      scopeThreeCategory,
    });

  return {
    inputBlock: (
      <ShippingInput
        emissionData={emissionData}
        editEmissionData={editEmissionData}
        setCalcLoading={setCalcLoading}
        setCalculatedMileage={setCalculatedMileage}
      />
    ),
    onTransactionSave,
    saveEnabled: shippingWeight && distanceTraveled && transportationMethod,
    tonsCo2e,
  };
};
export default useShippingInput;
