import React, { useState, useEffect, useContext, useRef } from "react";
import { FixedSizeList } from "react-window";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import {
  Box,
  Grid,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Tooltip,
  Typography,
  Badge,
  useTheme,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faCheck,
  faHourglassStart,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import {
  faBuilding,
  faUserFriends,
  faMoneyBill,
} from "@fortawesome/pro-solid-svg-icons";

import { Checkbox, CategoriesAvatar, TextField } from "@aclymatepackages/atoms";
import {
  formatDate,
  formatDecimal,
  letterSBoolean,
  ucFirstLetters,
} from "@aclymatepackages/formatters";
import {
  addDataRow,
  removeDataRow,
} from "@aclymatepackages/array-immutability-helpers";
import { hexToRgba } from "@aclymatepackages/converters";

import EditRowTransactions from "../../EditRowTransactions";

import EmissionsSourcesIcon from "../../../../atoms/icons/EmissionSourcesIcon";
import TagRowDisplay from "../../../../atoms/icons/TagRowDisplay";

import FilterPopper from "../../../../modules/filter/FilterPopper";

import { PlatformLayoutContext } from "../../../../../helpers/contexts/platformLayout";
import EmissionsVolumeForm from "../../../../inputs/emissions/EmissionsVolumeForm";
import useMeasurementSystem from "../../../../../helpers/hooks/measurementSystem";
import { onUpdateTransactions } from "../../../../../helpers/components/emissions";

dayjs.extend(isSameOrBefore);

const CHECKBOX_WIDTH = 48;
const SCROLLBAR_WIDTH = 15;
const TABLE_HEIGHT = 481;

const emissionStatuses = {
  confirmed: {
    icon: faCheck,
    color: "secondary",
    tooltip: "This emission is confirmed and added to your balance",
  },
  locked: {
    icon: faLock,
    color: "backgroundGray",
    tooltip:
      "This emission is locked because you've closed the accounting period for it",
  },
  unconfirmed: {
    icon: faEdit,
    color: "error",
    tooltip:
      "This emission has been added to your balance but is missing information to make it more accurate",
  },
  cleared: {
    icon: faHourglassStart,
    color: "travel",
    tooltip:
      "This emission hasn't been added to your balance yet because it hasn't happened yet",
  },
};

const sortAndOrderRows = (rows, { field, isAsc }) => {
  const adjustedField = () => {
    if (field === "status") {
      return "severity";
    }

    if (field === "carbonVolume") {
      return "tonsCo2e";
    }

    return field;
  };

  const sortedRows = [...rows].sort((a, b) => {
    if (a[adjustedField()] > b[adjustedField()]) {
      return -1;
    }
    if (a[adjustedField()] < b[adjustedField()]) {
      return 1;
    }
    return 0;
  });

  if (isAsc) {
    return sortedRows.reverse();
  }

  return sortedRows;
};

const ActionsTransactionsBlock = ({
  tonsCo2e,
  transactionId,
  section,
  acceptDisabled,
  disabled,
  setEmissions,
}) => {
  const { palette } = useTheme();

  const generateAcceptIconTitle = () => {
    if (section === "all-emissions") {
      return "Accept default value for this transaction";
    }

    if (section === "reject-emissions" && !tonsCo2e) {
      return "Add more information to this transaction to add it to your balance";
    }

    return "Add transaction to balance";
  };

  return (
    <Grid container wrap="nowrap">
      <Grid item>
        <Tooltip
          title={generateAcceptIconTitle()}
          disableHoverListener={acceptDisabled || !tonsCo2e}
        >
          <span>
            <IconButton
              disabled={acceptDisabled || !tonsCo2e}
              onClick={(e) => {
                onUpdateTransactions([
                  { id: transactionId, status: "confirmed" },
                ]);
                setEmissions((prevEmissions) =>
                  prevEmissions.map((emission) =>
                    emission.id === transactionId
                      ? { ...emission, status: "confirmed" }
                      : emission
                  )
                );

                return e.stopPropagation();
              }}
              size="large"
            >
              <CheckCircleIcon
                fontSize="small"
                style={{
                  color:
                    acceptDisabled || !tonsCo2e
                      ? "inherit"
                      : palette.success.main,
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title="Archive Transaction"
          disableHoverListener={disabled ? disabled : false}
        >
          <span>
            <IconButton
              disabled={disabled ? disabled : false}
              onClick={(e) => {
                onUpdateTransactions([{ id: transactionId, archived: true }]);
                setEmissions((prevEmissions) =>
                  prevEmissions.map((emission) =>
                    emission.id === transactionId
                      ? { ...emission, archived: true }
                      : emission
                  )
                );

                return e.stopPropagation();
              }}
              size="large"
            >
              <DeleteIcon
                fontSize="small"
                style={{
                  color: disabled ? "inherit" : palette.error.main,
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const VirtualizedList = ({
  height,
  width,
  itemCount,
  itemSize,
  className,
  style,
  rowRenderFunc,
}) => {
  const otherProps = {
    height,
    width,
    itemCount,
    itemSize,
    className,
    style,
  };
  return <FixedSizeList {...otherProps}>{rowRenderFunc}</FixedSizeList>;
};

const VirtualizedListCellComponent = ({
  column,
  width,
  align = "left",
  style: { height },
  children,
  findColumnWidth,
}) => {
  const columnWidth = width ? `${width}px` : `${findColumnWidth(column)}px`;

  return (
    <TableCell
      component="div"
      style={{
        width: columnWidth,
        maxWidth: columnWidth,
        height: height,
        maxHeight: height,
        boxSizing: "border-box",
        textOverflow: "ellipsis",
      }}
      align={align}
    >
      {children}
    </TableCell>
  );
};

const TableHeaderCell = ({
  annotation,
  field,
  label,
  isColumnActive,
  onSortClick,
  isAsc,
  style,
  headerCheckboxProps,
}) => {
  const theme = useTheme();

  const createTableLabel = () =>
    !!onSortClick ? (
      <TableSortLabel
        active={isColumnActive}
        direction={isAsc ? "asc" : "desc"}
        onClick={onSortClick}
        component="div"
      >
        {(label || field)?.toUpperCase()}
      </TableSortLabel>
    ) : (
      <Typography variant="inherit">
        {(label || field)?.toUpperCase()}
      </Typography>
    );

  return (
    <TableCell component="div" style={style}>
      {!!annotation && (
        <Tooltip title={annotation}>
          <HelpOutlineIcon
            color="action"
            style={{
              verticalAlign: "middle",
              paddingBottom: 2,
              marginRight: theme.spacing(0.5),
            }}
            fontSize="small"
          />
        </Tooltip>
      )}
      {field === "checkbox" ? (
        <Checkbox {...headerCheckboxProps} />
      ) : (
        createTableLabel()
      )}
    </TableCell>
  );
};

const TableHeader = ({
  columns,
  width,
  findColumnWidth,
  selectedSort = {},
  sortRows,
  headerCheckboxProps,
}) => {
  const { field: sortField, isAsc } = selectedSort;
  const { palette } = useTheme();

  const headerCellStyles = (field) => {
    const columnWidth = findColumnWidth(field);
    return {
      flexBasis: field ? `${columnWidth}px` : CHECKBOX_WIDTH,
      display: "flex",
      flexGrow: 0,
      flexShrink: 0,
      boxSizing: "border-box",
      fontWeight: 600,
      border: "none",
      flexWrap: "nowrap",
      alignItems: "center",
    };
  };

  return (
    <TableHead
      component="div"
      style={{
        width: `${width - 25}px`,
        backgroundColor: palette.backgroundGray.light,
      }}
    >
      <TableRow
        component="div"
        style={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
          width: `${width}px`,
        }}
      >
        {columns.map((column, idx) => (
          <TableHeaderCell
            key={`table-header-cell-${idx}`}
            style={headerCellStyles(column.field)}
            isColumnActive={column.field === sortField}
            isAsc={isAsc}
            onSortClick={sortRows && (() => sortRows(column.field))}
            headerCheckboxProps={headerCheckboxProps}
            {...column}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};

const VirtualizedTableRow = ({
  findColumnWidth,
  style,
  onClick,
  idx,
  tableCells,
}) => {
  const getCellComponentProps = (column) => ({
    column,
    style,
    findColumnWidth,
  });

  return (
    <TableRow
      style={{
        ...style,
        cursor: !!onClick ? "pointer" : "default",
      }}
      component="div"
      onClick={onClick ? () => onClick() : null}
      hover={!!onClick}
    >
      {tableCells.map(({ value, field, style }) => (
        <VirtualizedListCellComponent
          style={style}
          key={`table-row-${idx}-${field}`}
          {...getCellComponentProps(field)}
        >
          {value}
        </VirtualizedListCellComponent>
      ))}
    </TableRow>
  );
};

const VirtualizedTable = ({
  tableTitle,
  tableAction,
  width,
  rows,
  RowComponent,
  selectedTransaction,
  setSelectedTransaction,
  setSelectedEmission,
  tableColumns,
  selectedSort,
  sortRows,
  isAsc,
  rowProps = {},
  headerCheckboxProps = {},
  viewMode,
  tableRef,
}) => {
  const [columnWidths, setColumnWidths] = useState([]);

  useEffect(() => {
    const calculateColumnWidths = () => {
      const minWidthSum = tableColumns.reduce(
        (sum, { minWidth }) => Number(sum) + Number(minWidth),
        0
      );

      const newApproximateMinWidths = tableColumns.map((row) => {
        const { minWidth } = row;
        const rowPercentage = minWidth / minWidthSum;

        const newWidth = width * rowPercentage;
        return { ...row, minWidth: newWidth };
      });

      const newRoundedMinWidths = newApproximateMinWidths.map(
        ({ minWidth, ...otherProps }) => ({
          ...otherProps,
          minWidth: Math.floor(minWidth),
        })
      );

      const newMinWidthsSum = newRoundedMinWidths.reduce(
        (sum, { minWidth }) => Number(sum) + Number(minWidth),
        0
      );

      const columnWidths = newRoundedMinWidths.map(
        ({ minWidth, primary, ...column }) => {
          if (primary) {
            return { minWidth, ...column };
          }
          const newWidth =
            width - (newMinWidthsSum - minWidth) - SCROLLBAR_WIDTH;
          return { minWidth: newWidth, ...column };
        }
      );
      setColumnWidths(columnWidths);
    };

    if (width) {
      calculateColumnWidths();
    }
  }, [tableColumns, width]);

  const findColumnWidth = (field) =>
    Array.isArray(columnWidths) &&
    columnWidths.find((row) => row.field === field)?.minWidth;

  const VirtualizedTableRow = ({ index, style }) => {
    const rowData = rows[index];

    return (
      <RowComponent
        viewMode={viewMode}
        findColumnWidth={findColumnWidth}
        emission={rowData}
        style={style}
        selectedTransaction={selectedTransaction}
        setSelectedTransaction={setSelectedTransaction}
        setSelectedEmission={setSelectedEmission}
        idx={index}
        {...rowProps}
      />
    );
  };

  return (
    <>
      <Box mx={2} ref={tableRef}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="h6">{tableTitle}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>{tableAction}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Table style={{ width, minWidth: width }} component="div">
        <TableHeader
          columns={columnWidths}
          width={width}
          findColumnWidth={findColumnWidth}
          selectedSort={selectedSort}
          sortRows={sortRows}
          isAsc={isAsc}
          headerCheckboxProps={headerCheckboxProps}
        />
        <TableBody component="div">
          <VirtualizedList
            height={TABLE_HEIGHT}
            width={width}
            itemCount={rows.length}
            itemSize={80}
            rowRenderFunc={VirtualizedTableRow}
          />
        </TableBody>
      </Table>
    </>
  );
};

const EmissionsTableRowComponent = ({
  viewMode,
  style,
  findColumnWidth,
  emission,
  setSelectedTransaction,
  setSelectedEmission,
  idx,
  selectedTransactionIds,
  onTransactionCheck,
  areAnyTransactionsNonRecurring,
  setEmissions,
}) => {
  const {
    date,
    name,
    subcategory,
    type,
    status,
    tonsCo2e,
    scope,
    id,
    source,
    office,
  } = emission || {};

  const { convertCarbonUnits } = useMeasurementSystem();

  const { palette } = useTheme();

  const isManualEntryEmission = source === "manual-emission";
  const isTransaction = type === "transaction" || isManualEntryEmission;

  const {
    icon: statusIcon,
    color: statusColor,
    tooltip: statusTooltip,
  } = emissionStatuses[status] || {};

  const isTransactionNonRecurring = ![
    "default-utilities",
    "recurring",
  ].includes(source);

  const buildCategoryScopeDisplayObj = () => {
    const isShowingSubcategories = viewMode === "subcategories";

    const field = isShowingSubcategories ? "subcategory" : "scope";

    const value =
      type === "transaction" && isShowingSubcategories ? (
        <TagRowDisplay {...emission} />
      ) : (
        <CategoriesAvatar
          viewMode={viewMode}
          scope={scope}
          subcategory={subcategory}
        />
      );
    return { field, value, clickable: false };
  };

  const buildNameString = () => {
    if (source === "manual-entry" && office) {
      return `${name} (${office.name})`;
    }

    return name || "N/A";
  };

  const checkBoxForUnconfirmedEmissions = areAnyTransactionsNonRecurring
    ? [
        {
          value: (
            <Tooltip
              disableHoverListener={isTransactionNonRecurring}
              title="You can only select unconfirmed transactions."
            >
              <span>
                <Checkbox
                  disabled={
                    !isTransactionNonRecurring || source === "manual-emission"
                  }
                  value={selectedTransactionIds.includes(id)}
                  editValue={() => onTransactionCheck(id)}
                />
              </span>
            </Tooltip>
          ),
          field: "checkbox",
        },
      ]
    : [];

  const actionsForUnconfirmedEmissions = areAnyTransactionsNonRecurring
    ? [
        {
          value: (
            <ActionsTransactionsBlock
              tonsCo2e={tonsCo2e}
              transactionId={id}
              section="all-emissions"
              acceptDisabled={
                status === "confirmed" || subcategory === "events"
              }
              disabled={
                !isTransactionNonRecurring ||
                subcategory === "events" ||
                source === "manual-emission"
              }
              setEmissions={setEmissions}
            />
          ),
          field: "actions",
        },
      ]
    : [];

  const tableCells = [
    ...checkBoxForUnconfirmedEmissions,
    {
      value: formatDate(date),
      field: "date",
    },
    {
      value: (
        <Tooltip title={statusTooltip}>
          <div>
            <FontAwesomeIcon
              icon={statusIcon}
              size="lg"
              style={{ color: palette[statusColor]?.main }}
            />
          </div>
        </Tooltip>
      ),
      field: "status",
    },
    {
      value: <EmissionsSourcesIcon emission={emission} />,
      field: "source",
    },
    buildCategoryScopeDisplayObj(),
    {
      value: buildNameString(),
      field: "name",
    },
    {
      value: (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            {tonsCo2e
              ? formatDecimal(Math.abs(convertCarbonUnits(tonsCo2e)))
              : "N/A"}
          </Grid>
          {status === "unconfirmed" && subcategory !== "spend-based" && (
            <Grid item>
              <Tooltip
                title="This is the default value we estimated for this transaction. You can either provide more information about this transaction or accept the default value"
                style={{ color: palette.error.main }}
              >
                <HelpOutlineIcon />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      ),
      field: "carbonVolume",
    },
    ...actionsForUnconfirmedEmissions,
  ];

  const isClickable = isTransaction && status !== "locked";

  const handleRowClick = () => {
    if (!isClickable) {
      return;
    }

    if (isManualEntryEmission) {
      return setSelectedEmission(emission);
    }

    return setSelectedTransaction(emission);
  };

  return (
    <VirtualizedTableRow
      findColumnWidth={findColumnWidth}
      style={{
        ...style,
      }}
      idx={idx}
      onClick={() => handleRowClick()}
      tableCells={tableCells}
    />
  );
};

const EmissionsTable = ({
  width,
  emissions,
  setEmissions,
  viewMode,
  tableRef,
}) => {
  const { palette } = useTheme();
  const { carbonUnitLabel } = useMeasurementSystem();

  const findInitialSort = () => {
    const highSeverityRows = emissions.filter(({ severity }) => severity > 1);

    if (highSeverityRows.length) {
      return { field: "status", isAsc: false };
    }

    return {
      field: "date",
      isAsc: false,
    };
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedOffices, setSelectedOffices] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectedEmission, setSelectedEmission] = useState(null);
  const [selectedTransactionIds, setSelectedTransactionIds] = useState([]);
  const [selectedSort, setSelectedSort] = useState(findInitialSort());
  const [searchValue, setSearchValue] = useState("");
  const { isAsc } = selectedSort;

  useEffect(() => {
    const compareIdsAndTransactions = () => {
      return setSelectedTransactionIds((prevSelectedTransactionIds) =>
        prevSelectedTransactionIds.filter((id) =>
          emissions.find((emission) => emission.id === id)
        )
      );
    };

    compareIdsAndTransactions();
  }, [emissions]);

  const nonRecurringTransactions = emissions.filter(
    ({ source }) =>
      !["default-utilities", "recurring", "manual-emission"].includes(source)
  );
  const isAnyNonRecurringTransactions = nonRecurringTransactions.length;

  const buildTableColumns = () => {
    const actionsHeader = isAnyNonRecurringTransactions
      ? [{ minWidth: 70, field: "actions" }]
      : [];

    const checboxHeader = isAnyNonRecurringTransactions
      ? [{ minWidth: 50, field: "checkbox" }]
      : [];

    const scopeSubcategoryRowObj =
      viewMode === "subcategories"
        ? { minWidth: 100, field: "subcategory", label: "category" }
        : { minWidth: 50, field: "scope" };

    return [
      ...checboxHeader,
      { minWidth: 70, field: "date" },
      { minWidth: 70, field: "status", label: "Status" },
      { minWidth: 65, field: "source" },
      scopeSubcategoryRowObj,
      { minWidth: 150, field: "name", primary: true },
      { minWidth: 70, field: "carbonVolume", label: `${carbonUnitLabel} CO2` },
      ...actionsHeader,
    ];
  };

  const onHeaderCheck = () => {
    if (selectedTransactionIds.length === nonRecurringTransactions.length) {
      return setSelectedTransactionIds([]);
    }

    const allRowsArray = nonRecurringTransactions.map(({ id }) => id);

    return setSelectedTransactionIds(allRowsArray);
  };

  const onTransactionCheck = (transactionId) => {
    const rowIdx = selectedTransactionIds.findIndex(
      (id) => id === transactionId
    );

    if (rowIdx === -1) {
      return addDataRow(setSelectedTransactionIds, transactionId);
    }

    return removeDataRow(rowIdx, setSelectedTransactionIds)();
  };

  const sortRows = (field) =>
    setSelectedSort(({ isAsc: currentIsAsc }) => ({
      field,
      isAsc: !currentIsAsc,
    }));

  const onUpdateBulkTransactions = (updateObj) => {
    onUpdateTransactions(
      selectedTransactionIds.map((id) => ({ id, ...updateObj }))
    );
    setEmissions((prevEmissions) =>
      prevEmissions.map((emission) => {
        if (selectedTransactionIds.includes(emission.id)) {
          return { ...emission, ...updateObj };
        }

        return emission;
      })
    );

    return setSelectedTransactionIds([]);
  };

  const applyFilters = () => {
    const filterBySearch = (emission) => {
      const { name, subcategory } = emission;
      const searchValueLower = searchValue.toLowerCase();
      return (
        name.toLowerCase().includes(searchValueLower) ||
        subcategory.toLowerCase().includes(searchValueLower)
      );
    };

    const findEmissionsWithTags = (emissionTag, selectedTags) => {
      if (!selectedTags.length) {
        return true;
      }

      const selectedTagsNames = selectedTags.map(({ name }) => name);

      if (Array.isArray(emissionTag)) {
        return !!emissionTag.filter((tagObj) =>
          selectedTagsNames.includes(ucFirstLetters(tagObj.name))
        ).length;
      }

      return selectedTagsNames.includes(ucFirstLetters(emissionTag?.name));
    };

    const filterByEmployees = ({ employees }) =>
      findEmissionsWithTags(employees, selectedEmployees);

    const filterByOffice = ({ office }) =>
      findEmissionsWithTags(office, selectedOffices);

    const filterByKnownVendor = ({ knownVendor }) =>
      findEmissionsWithTags(knownVendor, selectedVendors);

    return emissions.filter((emission) => {
      return (
        filterByEmployees(emission) &&
        filterByOffice(emission) &&
        filterByKnownVendor(emission) &&
        filterBySearch(emission)
      );
    });
  };

  const filteredSummaryTableEmissions = applyFilters();

  const headerCheckboxProps = {
    value: selectedTransactionIds.length === nonRecurringTransactions.length,
    editValue: onHeaderCheck,
    indeterminate:
      !!selectedTransactionIds.length &&
      selectedTransactionIds.length < nonRecurringTransactions.length,
  };

  const selectedTransactionStatuses = selectedTransactionIds.map(
    (selectedId) => {
      const { status } = emissions.find(({ id }) => id === selectedId);
      return { id: selectedId, status };
    }
  );
  const areAnySelectedTransactionsConfirmed = selectedTransactionStatuses.find(
    ({ status }) => status === "confirmed"
  );

  const buildFilterPopperProps = () => {
    const populateFilterAutocompletes = (type) => {
      const rowsByFilterType = emissions.filter((row) => row[type]);

      const namesByFilterType = rowsByFilterType.map((row) => {
        const { employees } = row;

        if (employees?.length && type === "employees") {
          return employees.map(({ name }) => name);
        }

        return row[type].name;
      });

      const formattedNames =
        type === "employees"
          ? namesByFilterType.reduce(
              (acc, names = []) => [...acc, ...names],
              []
            )
          : namesByFilterType;

      const namesNoDups = formattedNames.length
        ? [...new Set(formattedNames)]
        : [];

      return namesNoDups
        .filter((name) => name)
        .map((name) => ({ name: ucFirstLetters(String(name)) }));
    };

    const filterRows = [
      {
        icon: faBuilding,
        title: "Offices",
        type: "office",
        autocomplete: {
          availableOptions: populateFilterAutocompletes("office"),
          value: selectedOffices,
          setValue: setSelectedOffices,
          label: "Offices",
        },
      },
      {
        icon: faUserFriends,
        title: "Employees",
        type: "employee",
        autocomplete: {
          availableOptions: populateFilterAutocompletes("employees"),
          value: selectedEmployees,
          setValue: setSelectedEmployees,
          label: "Employees",
        },
      },
      {
        icon: faMoneyBill,
        title: "Vendors",
        type: "vendor",
        autocomplete: {
          availableOptions: populateFilterAutocompletes("knownVendor"),
          value: selectedVendors,
          setValue: setSelectedVendors,
          label: "Vendors",
        },
      },
    ];

    const resetFilters = () => {
      setSelectedEmployees([]);
      setSelectedOffices([]);
      return setSelectedVendors([]);
    };

    return { filterRows, resetFilters };
  };

  const isFiltered =
    selectedEmployees.length ||
    selectedOffices.length ||
    selectedVendors.length;

  return (
    <>
      {!!selectedTransaction && (
        <EditRowTransactions
          transaction={selectedTransaction || {}}
          setIsSlideOpen={setSelectedTransaction}
          saveButtonText="Save Changes"
          setEmissions={setEmissions}
        />
      )}
      {!!selectedEmission && (
        <EmissionsVolumeForm
          existingEmission={selectedEmission}
          setDialogOpen={setSelectedEmission}
        />
      )}
      <VirtualizedTable
        tableRef={tableRef}
        width={width}
        rows={sortAndOrderRows(filteredSummaryTableEmissions, selectedSort)}
        filteredRows={filteredSummaryTableEmissions}
        selectedSort={selectedSort}
        sortRows={sortRows}
        isAsc={isAsc}
        RowComponent={EmissionsTableRowComponent}
        rowProps={{
          selectedTransactionIds,
          onTransactionCheck,
          areAnyTransactionsNonRecurring: isAnyNonRecurringTransactions,
          setEmissions,
        }}
        setSelectedTransaction={setSelectedTransaction}
        setSelectedEmission={setSelectedEmission}
        tableColumns={buildTableColumns()}
        tableTitle={
          isFiltered || searchValue.length
            ? `Filtered Emissions (${filteredSummaryTableEmissions.length})`
            : selectedTransactionIds.length
            ? `Selected Transactions (${selectedTransactionIds.length})`
            : `All Emissions (${filteredSummaryTableEmissions.length})`
        }
        viewMode={viewMode}
        headerCheckboxProps={headerCheckboxProps}
        tableAction={
          <Grid container>
            <Grid item>
              <Tooltip title="Filter Emissions">
                <span>
                  <IconButton
                    onClick={(e) =>
                      setAnchorEl((currentAnchorEl) =>
                        currentAnchorEl ? null : e.currentTarget
                      )
                    }
                  >
                    {isFiltered ? (
                      <Badge
                        variant="dot"
                        color="secondary"
                        style={{ zIndex: 0 }}
                      >
                        <FilterListIcon />
                      </Badge>
                    ) : (
                      <FilterListIcon />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
              <FilterPopper
                open={!!anchorEl}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                filterPopperProps={{
                  ...buildFilterPopperProps(),
                  title: "Filter Emissions",
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Search"
                value={searchValue}
                setValue={setSearchValue}
              />
            </Grid>
            {!!selectedTransactionIds.length && (
              <Grid item>
                <Tooltip
                  title={
                    areAnySelectedTransactionsConfirmed
                      ? "You can't accept default emissions for confirmed transactions"
                      : `Accept Default Emissions for ${
                          selectedTransactionIds.length
                        } transaction${letterSBoolean(selectedTransactionIds)}`
                  }
                >
                  <span>
                    <IconButton
                      size="large"
                      disabled={areAnySelectedTransactionsConfirmed}
                    >
                      <CheckCircleIcon
                        onClick={() =>
                          onUpdateBulkTransactions({ status: "confirmed" })
                        }
                        style={{
                          color: hexToRgba(
                            palette.success.main,
                            areAnySelectedTransactionsConfirmed ? 0.5 : 1
                          ),
                        }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title={`Archive ${
                    selectedTransactionIds.length
                  } transaction${letterSBoolean(selectedTransactionIds)}`}
                >
                  <span>
                    <IconButton size="large">
                      <DeleteIcon
                        onClick={() =>
                          onUpdateBulkTransactions({ archived: true })
                        }
                        style={{ color: palette.error.main }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        }
      />
    </>
  );
};

const PrimaryViewTable = ({ emissions, viewMode, setEmissions }) => {
  const { sideNavOpen } = useContext(PlatformLayoutContext);

  const [tableWidth, setTableWidth] = useState(0);

  const tableRef = useRef(null);

  useEffect(() => {
    if (tableRef.current) {
      setTableWidth(tableRef.current?.offsetWidth);
    }
  }, [tableRef, setTableWidth]);

  useEffect(() => {
    setTimeout(() => {
      setTableWidth(tableRef.current?.offsetWidth);
    }, 300);
  }, [tableRef, sideNavOpen, setTableWidth]);

  return (
    <EmissionsTable
      tableRef={tableRef}
      width={tableWidth}
      emissions={emissions}
      viewMode={viewMode}
      setEmissions={setEmissions}
    />
  );
};
export default PrimaryViewTable;
